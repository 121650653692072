import { baseApi } from "./apiSingleton"; // Import baseApi from the apiSingleton module

// Define types for API responses and request bodies
export type IInitConsortiaResponse = Array<{
  code: string;
  commission: null;
  id: number;
  name: string;
}>;

export type IInitMarketsResponse = Array<{
  code: string;
  id: number;
  name: string;
}>;

export type IInitRegulatoryResponse = Array<{
  code: string;
  id: number;
  name: string;
}>;

export interface ICreateAgencyRequest {
  sales_area: null;
  api_accounts: string[];
  name: string;
  address: Array<{ name: string; value: string }>;
  telephone: string;
  website: string;
  account_type: string;
  consortia: string;
  market: string;
  agent_address: string;
  email: string;
  b2b_password: string;
  code: string;
  commission_level: number;
  credit_limit: number;
}

export interface ICreateAgentRequest {
  address: Array<{ name: string; value: string }>;
  sales_area: null;
  firstName: string;
  surname: string;
  telephone: string;
  agency: number;
  position: string;
  email: string;
  b2b_password: string;
  code: string;
}

export type ISearchAgencyResponse = Array<Record<string, string>>;

export interface IGetAgencyDetailsResponse {
  commission_level: string;
  created_by: string;
  market: string;
}

// Define API endpoints using the builder's query method
export const AgencyAPI = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    initConsortia: builder.query<IInitConsortiaResponse, null>({
      query: () => ({
        url: "/cnf/consortia/",
        method: "GET",
      }),
    }),
    initMarkets: builder.query<IInitMarketsResponse, null>({
      query: () => ({
        url: "/cnf/market/",
        method: "GET",
      }),
    }),
    initRegulatory: builder.query<IInitRegulatoryResponse, null>({
      query: () => ({
        url: "/cnf/regulatory/",
        method: "GET",
      }),
    }),
    createAgency: builder.query<{ id: number }, ICreateAgencyRequest>({
      query: (body) => ({
        url: "/ops/agency/",
        method: "POST",
        body,
      }),
    }),
    createAgent: builder.query<null, ICreateAgentRequest>({
      query: (body) => ({
        url: "/ops/agent/",
        method: "POST",
        body,
      }),
    }),
    searchAgencies: builder.query<ISearchAgencyResponse, null>({
      query: () => ({
        url: "/ops/agency/",
        method: "GET",
      }),
    }),
    getAgencyDetails: builder.query<IGetAgencyDetailsResponse, string>({
      query: (agencyId) => ({
        url: `/ops/agency/${agencyId}`,
        method: "GET",
      }),
    }),
  }),
});

// Destructure useLazySearchAgenciesQuery from AgencyAPI
export const { useLazySearchAgenciesQuery } = AgencyAPI;
