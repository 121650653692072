import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
  retry,
} from "@reduxjs/toolkit/query/react"; // Importing necessary utilities from Redux Toolkit for creating APIs

import { store } from "../store"; // Importing the Redux store

// Function to prepare headers for API requests
const prepareHeaders = (headers: Headers) => {
  const { token } = store.getState().session; // Retrieving the token from the session state

  if (token) {
    // If token exists
    headers.set("authorization", `Bearer ${token}`); // Set authorization header with the token
  }

  return headers; // Return modified headers
};

// Base query function for session-related queries
const baseApiQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = (args, WebApi, extraOptions) => {
  const baseUrl = store.getState().environment.api_url; // Retrieving the base URL from the environment state
  const rawBaseQuery = fetchBaseQuery({ baseUrl, prepareHeaders }); // Creating base query using fetchBaseQuery

  return rawBaseQuery(args, WebApi, { ...extraOptions }); // Executing the base query function with provided arguments
};

const posQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = (args, WebApi, extraOptions) => {
  const baseUrl = store.getState().environment.pos_url;
  const rawBaseQuery = fetchBaseQuery({ baseUrl, prepareHeaders });

  return rawBaseQuery(args, WebApi, { ...extraOptions });
};

const searchQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = (args, WebApi, extraOptions) => {
  const baseUrl = store.getState().environment.search_url;
  const rawBaseQuery = fetchBaseQuery({ baseUrl, prepareHeaders });

  return rawBaseQuery(args, WebApi, { ...extraOptions });
};

const paymentQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = (args, WebApi, extraOptions) => {
  const baseUrl = store.getState().environment.payment_url;
  const rawBaseQuery = fetchBaseQuery({ baseUrl, prepareHeaders });

  return rawBaseQuery(args, WebApi, { ...extraOptions });
};

const posQueryWithRetry = retry(posQuery, { maxRetries: 3 });
const searchQueryWithRetry = retry(searchQuery, { maxRetries: 3 });

// Base query for fetching environment-related data
const environmentQuery = fetchBaseQuery({
  baseUrl: `${window.location.protocol}//${window.location.hostname}`, // Setting base URL to current protocol and hostname
});

// Creating APIs using createApi from Redux Toolkit
export const baseApi = createApi({
  reducerPath: "BaseAPI",
  baseQuery: baseApiQuery,
  endpoints: () => ({}), // Defining endpoints for session API
});

export const posApi = createApi({
  reducerPath: "PosAPI",
  baseQuery: posQueryWithRetry,
  endpoints: () => ({}),
});

export const searchApi = createApi({
  reducerPath: "SearchAPI",
  baseQuery: searchQueryWithRetry,
  endpoints: () => ({}),
});

export const paymentApi = createApi({
  reducerPath: "PaymentAPI",
  baseQuery: paymentQuery,
  endpoints: () => ({}),
});

export const localesApi = createApi({
  reducerPath: "LocalesAPI",
  baseQuery: environmentQuery,
  endpoints: () => ({}),
});

const api = {
  base: baseApi,
  pos: posApi,
  search: searchApi,
  payment: paymentApi,
};

export default api; // Exporting the combined API object
