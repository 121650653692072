import { CURRENT_BOOKINGS, PREVIOUS_BOOKINGS, SEARCH } from "./routes";
import { store } from "../../store/store";

const TILES = {
  start: {
    title: () => store.getState().environment.start_booking_title,
    description: () => store.getState().environment.start_booking_description,
    color: "teal",
    path: SEARCH,
  },
  current: {
    title: () => store.getState().environment.current_bookings_title,
    description: () =>
      store.getState().environment.current_bookings_description,
    color: "lilac",
    path: CURRENT_BOOKINGS,
  },
  previous: {
    title: () => store.getState().environment.previous_bookings_title,
    description: () =>
      store.getState().environment.previous_bookings_description,
    color: "lilac",
    path: PREVIOUS_BOOKINGS,
  },
};

const AGENT_HOME = { TILES };

export default AGENT_HOME;
