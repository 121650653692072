// Import ReactDOM for portal rendering, React hooks for state management.
import ReactDOM from "react-dom";
import { useEffect, useState } from "react";

// Redux action for hiding toasts, and hooks for Redux state management.
import { hideToast } from "../../../store/slices/toastSlice";
import { useTypedDispatch, useTypedSelector } from "../../../store/store";

// Component for individual toasts.
import Toast from "./molecules/Toast";

// Import SCSS module for styling.
import styles from "./index.module.scss";

// TypeScript interface for defining the shape of a toast notification.
interface IToast {
  isVisible: boolean;
  message: string;
  type: string;
  duration: number;
}

// Constant for the duration to switch between toasts.
const SWITCH_TOAST_DURATION = 5000;
// Default state for an inactive toast.
const DEFAULT_ACTIVE_TOAST = {
  isVisible: false,
  message: "",
  type: "",
  duration: 0,
};

// Container component for managing and displaying toast notifications.
function ToastContainer() {
  // Redux dispatch hook.
  const dispatch = useTypedDispatch();

  // Accessing the current toast state from Redux.
  const toastState = useTypedSelector((state) => state.toast);

  // State for managing the queue of toasts and the currently active toast.
  const [toastQueue, setToastQueue] = useState([DEFAULT_ACTIVE_TOAST]);
  const [activeToast, setActiveToast] = useState<IToast>(DEFAULT_ACTIVE_TOAST);

  // Function to hide the currently active toast.
  const hideActiveToast = () => {
    setActiveToast((prevToast) => ({ ...prevToast, isVisible: false }));

    // Reset active toast after a set duration.
    setTimeout(() => {
      setActiveToast(DEFAULT_ACTIVE_TOAST);
    }, SWITCH_TOAST_DURATION);
  };

  // Function to display the next toast in the queue.
  const showNextToast = () => {
    if (toastQueue.length > 0) {
      const nextToast = toastQueue[0];

      // Set the next toast as active and schedule its removal from the queue.
      setActiveToast(nextToast);

      setTimeout(() => {
        setToastQueue((prevQueue) => prevQueue.slice(1));

        hideActiveToast();
        dispatch(hideToast());
      }, nextToast.duration);
    }
  };

  // Effect to enqueue new toasts from Redux state.
  useEffect(() => {
    if (toastState.message) {
      const newToast = { ...toastState, isVisible: true };

      setToastQueue([...toastQueue, newToast]);
    }
  }, [toastState.message]);

  // Effect to display toasts from the queue as they become active.
  useEffect(() => {
    if (!activeToast.message) {
      showNextToast();
    }
  }, [toastQueue, activeToast]);

  // Render the toast container using a React portal for global positioning.
  return ReactDOM.createPortal(
    <div className={styles.container}>
      <Toast {...activeToast} closeToast={hideActiveToast} />
    </div>,
    document.body,
  );
}

// Export the ToastContainer for use throughout the application.
export default ToastContainer;
