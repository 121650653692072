// Import necessary dependencies
import classNames from "classnames"; // Library for conditionally joining class names together
import { useTranslation } from "react-i18next"; // Hook for accessing translation functionality

// Import hooks and types from the Redux store
import { useTypedSelector } from "../../../../../store/store"; // Custom hook for accessing typed Redux store state
import { Room } from "../../../../../store/slices/roomsSlice"; // Type definition for a room
import { currencyToFormat } from "../../../../../utils/helpers/currency"; // Helper function for formatting currency
import { ICruise, Market } from "../../../../../store/slices/searchSlice"; // Type definition for a cruise

import styles from "./index.module.scss"; // CSS Module styles for StateroomsMarkets component

// Define an array of available market types
const AVAILABLE_MARKETS: Array<keyof ICruise["markets"]> = [
  "inside",
  "outside",
  "balcony",
  "suite",
];

// Define props interface for StateroomsMarkets component
interface IStateroomMarketsProps {
  room?: Room;
  handleChoseMarket: (stateroom: Room) => void;
}

/**
 * Renders a component displaying available stateroom market options.
 *
 * @param {Room} room - The current room object.
 * @param {Function} handleChoseMarket - Function to handle choosing a market for the room.
 * @returns {JSX.Element} JSX representing the StateroomsMarkets component.
 */
function StateroomsMarkets({
  room,
  handleChoseMarket,
}: IStateroomMarketsProps) {
  const { t } = useTranslation(); // Access translation functions from i18next

  // Select relevant state slices from the Redux store using custom typed selectors
  const { cruise } = useTypedSelector((state) => state.search); // Current cruise data
  const { rooms } = useTypedSelector((state) => state.rooms); // Room data
  const { subtract_gft } = useTypedSelector((state) => state.environment); // Whether to subtract government fees and taxes

  // Callback function for handling market selection
  const handleMarketClick = (marketType: keyof ICruise["markets"]) => () => {
    // Create a new stateroom object with the selected market type
    const updatedStateroom = structuredClone({
      ...room,
      marketType,
    });

    // Remove the grade from the stateroom object
    delete updatedStateroom.grade;

    // Scroll to the pricing section
    const element = document.getElementById("pricing");

    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    // Call the parent component's handleChoseMarket callback with the updated stateroom
    handleChoseMarket(updatedStateroom);
  };

  // Return JSX for rendering the component
  return (
    // Container for the entire component
    <div className={styles.container}>
      {/* Block containing market options */}
      <div className={styles.block}>
        {/* Title for the market options */}
        <p className={styles.title}>{t("staterooms available")}</p>

        {/* Container for market options */}
        <div className={styles.markets}>
          {/* Map through available market types */}
          {AVAILABLE_MARKETS.map((marketKey) => {
            // Current market data
            const market = cruise?.markets[marketKey] as Market;

            // Calculate the price and format it as currency
            const price =
              market?.price && market?.price !== "0"
                ? currencyToFormat(
                    parseInt(
                      `${+market.price - +(subtract_gft ? market.gft : 0)}`,
                    ),
                    rooms?.[1]?.pricing?.payment_schedule?.[0]?.currency ??
                      cruise?.markets.currency ??
                      "USD",
                  ).slice(0, -3)
                : "N/A";

            // Determine if the current market is active
            const isActive = marketKey === room?.marketType;

            // Determine if the current market is disabled
            const isDisabled = price === "N/A";

            // Return JSX for rendering each market option
            return (
              <div
                key={marketKey}
                className={classNames(styles.market, {
                  [styles.market_active]: isActive, // Apply active style if isActive is true
                  [styles.market_disabled]: isDisabled, // Apply disabled style if isDisabled is true
                })}
                // Spread onClick event only if the market is not disabled
                {...(!isDisabled
                  ? { onClick: handleMarketClick(marketKey) }
                  : {})}>
                {/* Market name */}
                <p className={styles.marketName}>{marketKey}</p>
                {/* "From" label */}
                <p className={styles.from}>{t("From")}</p>
                {/* Price information */}
                <p className={styles.price}>
                  {price}
                  {/* Append "pp" if not disabled */}
                  {!isDisabled ? "pp" : ""}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

// Export the Staterooms
export default StateroomsMarkets;
