// Importing the utility for generating unique IDs
import { nanoid } from "@reduxjs/toolkit";

// Importing component-specific styles
import styles from "./index.module.scss";

// Interface for component props
interface IRadioButtonProps {
  label: string; // The text label for the radio button
  isChecked: boolean; // Indicates if the radio button is checked
  valueKey: string; // A key to identify the radio button value
  onChange: ({ value, valueKey }: { value: boolean; valueKey: string }) => void; // Callback function for when the radio button state changes
}

// The RadioButton component
function RadioButton({
  label, // The text label for the radio button
  isChecked, // Indicates if the radio button is checked
  valueKey, // A key to identify the radio button value
  onChange, // Callback function for when the radio button state changes
}: IRadioButtonProps) {
  const id = nanoid(); // Generating a unique ID for the radio button

  // Function to handle the change event of the radio button
  const handleChange = (value: boolean) => () => {
    onChange({ value, valueKey }); // Calling the onChange callback with the new value and valueKey
  };

  // Returning the JSX for the radio button
  return (
    <div className={styles.container} onClick={handleChange(true)}>
      <input
        id={id} // Unique ID for the input element
        type="radio" // Setting the input type to radio
        checked={isChecked} // Setting the checked state
        className={styles.field} // Applying component-specific styles
        onChange={handleChange(true)} // Handling change events
      />

      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
    </div>
  );
}

// Exporting the RadioButton component
export default RadioButton;
