// Importing useSelector hook from Redux toolkit for accessing Redux state.
import { useTypedSelector } from "../../../../../store/store";

// Importing Checkbox component for rendering checkboxes.
import Checkbox from "../../../../shared/Checkbox";

// Importing CSS module for styling.
import styles from "./index.module.scss";

// Defining the interface for the component's props.
interface ILegalDataProps {
  checkbox_1_checked: boolean;
  checkbox_2_checked: boolean;
  checkbox_3_checked: boolean;
  checkbox_4_checked: boolean;
  checkbox_5_checked: boolean;
  onChange: ({ value, valueKey }: { value: boolean; valueKey: string }) => void;
}

// LegalData component for displaying legal checkboxes and handling changes.
function LegalData({
  checkbox_1_checked,
  checkbox_2_checked,
  checkbox_3_checked,
  checkbox_4_checked,
  checkbox_5_checked,
  onChange,
}: ILegalDataProps) {
  // Accessing environment state from Redux store.
  const {
    privacy_policy,
    payment_checkbox_1,
    payment_checkbox_2,
    payment_checkbox_3,
    payment_checkbox_4,
    payment_checkbox_5,
  } = useTypedSelector((state) => state.environment);

  // Function to handle checkbox value changes.
  const handleChange = ({
    value,
    valueKey,
  }: {
    value: boolean;
    valueKey: string;
  }) => onChange({ value, valueKey });

  // Function to parse text and convert markdown links to HTML links.
  function parseTextWithLinks(text: string) {
    // eslint-disable-next-line no-useless-escape
    const linkRegex = /\[([^\]]+)\]\(([^\)]+)\)/g;

    const parsedText = text.replace(linkRegex, function (match, text, url) {
      return `<a class=${styles.link} href="${url}" target="_blank" rel="noreferrer">${text}</a>`;
    });

    return parsedText;
  }

  // Rendering the legal data with parsed text for markdown links.
  return (
    <div className={styles.container}>
      {privacy_policy && (
        <p>
          <>
            <span
              dangerouslySetInnerHTML={{
                __html: parseTextWithLinks(privacy_policy),
              }}
            />
          </>
        </p>
      )}

      {payment_checkbox_1 && (
        <Checkbox
          value={checkbox_1_checked}
          valueKey="checkbox_1"
          onChange={handleChange}
          label={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(payment_checkbox_1),
                }}
              />
            </>
          }
        />
      )}

      {payment_checkbox_2 && (
        <Checkbox
          value={checkbox_2_checked}
          valueKey="checkbox_2"
          onChange={handleChange}
          label={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(payment_checkbox_2),
                }}
              />
            </>
          }
        />
      )}

      {payment_checkbox_3 && (
        <Checkbox
          value={checkbox_3_checked}
          valueKey="checkbox_3"
          onChange={handleChange}
          label={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(payment_checkbox_3),
                }}
              />
            </>
          }
        />
      )}

      {payment_checkbox_4 && (
        <Checkbox
          value={checkbox_4_checked}
          valueKey="checkbox_4"
          onChange={handleChange}
          label={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(payment_checkbox_4),
                }}
              />
            </>
          }
        />
      )}

      {payment_checkbox_5 && (
        <Checkbox
          value={checkbox_5_checked}
          valueKey="checkbox_5"
          onChange={handleChange}
          label={
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: parseTextWithLinks(payment_checkbox_5),
                }}
              />
            </>
          }
        />
      )}
    </div>
  );
}

// Exporting the component for use in other parts of the application.
export default LegalData;
