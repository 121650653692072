// Import necessary modules and components
import classNames from "classnames"; // Module for conditional classnames
import { useTranslation } from "react-i18next"; // Translation hook for multilingual support

import { Room } from "../../../../../store/slices/roomsSlice"; // Room object from Redux store slice
import { Cabin } from "../../../../../store/services/CabinSelectService"; // Cabin object from CabinSelect service
import { useTypedSelector } from "../../../../../store/store"; // Redux selector hook for typed state

import Button from "../../../../shared/Button"; // Custom Button component
import LoadingContainer from "../../../../containers/LoadingContainer"; // Container component for loading state

import styles from "./index.module.scss"; // Importing SCSS module for styling.

// Define the props interface for the Cabins component
interface ICabinsProps {
  cabins?: Cabin[]; // Optional array of cabin objects
  room?: Room; // Optional room object
  handleChoseCabin: (room: Room) => void; // Callback function for handling cabin selection
}

/**
 * Cabins component for displaying cabin selection options.
 * @param {Array<Cabin>} cabins Array of cabin objects
 * @param {Room} room Selected room object
 * @param {Function} handleChoseCabin Callback function for handling cabin selection
 */
function Cabins({ cabins, room, handleChoseCabin }: ICabinsProps) {
  const { t } = useTranslation(); // Translation hook for multilingual support

  // Redux selector hook to get the loading state of cabins
  const { isCabinsLoading } = useTypedSelector((state) => state.rooms);

  /**
   * Callback function to handle cabin selection.
   * @param {Cabin} cabin Selected cabin object
   * @param {boolean} isActive Boolean indicating if the cabin is active
   */
  const handleCabinClick = (cabin: Cabin, isActive: boolean) => () => {
    // Clone the current room object to prevent direct mutation
    const updatedStateroom = structuredClone(room ?? {});

    // If the cabin is already active, remove it from the room
    if (isActive) {
      delete updatedStateroom?.cabin;
    } else {
      // Scroll to the cabins section when selecting a cabin
      updatedStateroom.cabin = cabin;

      const element = document.getElementById("cabins");

      updatedStateroom.cabin = cabin;

      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }

    // Invoke the callback function to update the selected room
    handleChoseCabin(updatedStateroom);
  };

  return (
    <div className={styles.container}>
      <p className={styles.title}>{t("select a stateroom")}</p>

      <div className={styles.cabins}>
        {/* Display loading container while cabins data is loading */}
        <LoadingContainer isLoading={isCabinsLoading || !cabins || !room}>
          {/* Check if cabins data exists */}
          {cabins?.length ? (
            cabins?.map((el) => {
              // Check if the current cabin is active
              const isActive = el.number === room?.cabin?.number;

              return (
                <div
                  key={el.number}
                  className={classNames(styles.cabin, {
                    [styles.cabin_active]: isActive,
                  })}>
                  <div className={styles.left}>
                    <p className={styles.name}>
                      {t("stateroom")} {el.number}
                    </p>

                    <div className={styles.row}>
                      <div className={styles.cell}>
                        <span className={styles.cellKey}>
                          {t("max occupancy")}
                        </span>

                        <span className={styles.cellValue}>
                          {el.maximum_occupancy}
                        </span>
                      </div>

                      <div className={styles.cell}>
                        <span className={styles.cellKey}>{t("size")}</span>
                        <span className={styles.cellValue}>{el.size}</span>
                      </div>
                    </div>
                  </div>

                  {/* Button to select/deselect cabin */}
                  <Button
                    className={styles.button}
                    label={isActive ? t("de-select") : t("select")}
                    variant={isActive ? "secondary" : "primary"}
                    onClick={handleCabinClick(el, isActive)}
                  />
                </div>
              );
            })
          ) : (
            // Render message when no cabins data is available
            <p>{t("No data to display")}</p>
          )}
        </LoadingContainer>
      </div>
    </div>
  );
}

// Exporting the Cabins component as default.
export default Cabins;
