// Import the EnvironmentGuard component, used for checking if the environment setup is correct before rendering children.
import EnvironmentGuard from "./EnvironmentGuard";
// Import the SessionGuard component, used for session-related checks (like authentication) before rendering children.
import SessionGuard from "./SessionGuard";

// Define the RootGuard component that wraps other components with both environment and session checks.
function RootGuard({ children }: React.PropsWithChildren) {
  // The component uses React's composition model to wrap any children inside the EnvironmentGuard and SessionGuard components.
  // This setup ensures that environment and session checks are passed before rendering the children.
  return (
    // First layer of guarding: checks related to the environment (e.g., feature flags, API availability).
    <EnvironmentGuard>
      {/* Second layer of guarding: checks related to the user session (e.g., authentication). */}
      <SessionGuard>{children}</SessionGuard>
    </EnvironmentGuard>
  );
}

// Export the RootGuard component to be used around the app's root or specific parts needing both checks.
export default RootGuard;
