import { currencyToFormat } from "./currency";

export const getCancellationPolicyDaysRange = (days: number) => {
  if (days > 121) {
    return "121 days and greater";
  } else if (days <= 120 && days > 90) {
    return "120 to 91 days";
  } else if (days <= 90 && days > 75) {
    return "90 to 76 days";
  } else if (days <= 75 && days > 45) {
    return "75 to 46 days";
  } else {
    return "45 days and less";
  }
};

export const getCancellationPolicyValue = (item: {
  type: string;
  value: number;
  currency: string;
}) => {
  if (item.type === "percentage") {
    return `${item.value}%`;
  }

  return `${currencyToFormat(item.value, item.currency ?? "USD")} pp`;
};
