// React hook for managing component state.
import { useState } from "react";
// Hook for internationalization to support multiple languages.
import { useTranslation } from "react-i18next";

// Constants for toast notifications.
import TOAST from "../../../utils/constants/toast";
// Hook for dispatching actions to the Redux store.
import { useTypedDispatch, useTypedSelector } from "../../../store/store";
// Utility for validating the form inputs.
import { validateForgottenPassword } from "../../../utils/validation";
// Custom hook for triggering the forgotten password flow.
import { useLazySendResetTokenQuery } from "../../../store/services/SessionService";
// Action creator for displaying toast notifications.
import { showToast } from "../../../store/slices/toastSlice";

// Reusable UI components for form input and buttons.
import Input from "../../shared/Input";
import Button from "../../shared/Button";

// CSS module for styling.
import styles from "./index.module.scss";

// Type definitions for handling form input changes.
type InputChangeHandler = Record<string, string>;

// Interface for the inputs to be validated.
interface IValidationInputs {
  email: string;
}

// Interface for managing the input state, including validation errors.
interface IInputs {
  email: { value: string; errorMessage: string };
}

// The functional component definition.
function ForgottenPassword() {
  // Hooks for internationalization and dispatching Redux actions.
  const { t } = useTranslation();
  const dispatch = useTypedDispatch();
  // Custom hook for making the API call to send a reset password token.
  const [sendResetToken] = useLazySendResetTokenQuery();

  const { forgotten_password_page_background } = useTypedSelector(
    (state) => state.environment,
  );

  // State for managing loading status and form inputs.
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState<IInputs>({
    email: { value: "", errorMessage: "" },
  });

  // Handler for form input changes.
  const handleInputChange = ({ value, valueKey }: InputChangeHandler) => {
    setInputs((prev) => ({
      ...prev,
      [valueKey]: { errorMessage: "", value },
    }));
  };

  // Handler for updating the form state with validation errors.
  const handleFormError = (errors: IValidationInputs) => {
    const updatedInputs = structuredClone(inputs);

    Object.keys(errors).forEach((errorKey) => {
      if (updatedInputs[errorKey as keyof IInputs]) {
        updatedInputs[errorKey as keyof IInputs].errorMessage =
          errors[errorKey as keyof IValidationInputs];
      }
    });

    setInputs(updatedInputs);
  };

  // Function to initiate the password reset process.
  const resetPassword = async (credentials: IValidationInputs) => {
    setIsLoading(true);

    const { isSuccess, isError } = await sendResetToken({
      email: credentials.email,
    });

    setIsLoading(false);

    if (isError) {
      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          message: t("Something went wrong"),
          duration: TOAST.DEFAULT_DURATION,
        }),
      );

      handleFormError({
        email: t(
          "This email doesn’t match our records. Please try again or another email.",
        ),
      });
    }

    if (isSuccess) {
      dispatch(
        showToast({
          type: TOAST.SUCCESS_TYPE,
          message: t("Link successfully sent"),
          duration: TOAST.DEFAULT_DURATION,
        }),
      );
    }
  };

  // Handler for the form submission event.
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    validateForgottenPassword({
      data: {
        email: inputs.email.value,
      },
      onSuccess: async (validData: IValidationInputs) =>
        await resetPassword(validData),
      onError: (errors: IValidationInputs) => handleFormError(errors),
    });
  };

  // Rendering the component's UI.
  return (
    <div
      className={styles.container}
      style={{
        background: `url(${forgotten_password_page_background}) center/cover`,
      }}>
      <div className={styles.content}>
        {/* Title and explanatory text. */}
        <p className={styles.title}>{t("forgotten password")}</p>

        <p className={styles.subtitle}>
          {t(
            "It looks like you’ve forgotten your password. We can help. We’ll send " +
              "you an email to the registered account that matches your email with a " +
              "link to reset your password.",
          )}
        </p>

        {/* Form for submitting the email address. */}
        <form className={styles.form} onSubmit={handleSubmit}>
          <Input
            className={styles.input}
            value={inputs.email.value}
            valueKey="email"
            label={t("registered email address")}
            placeholder={t("Type your email")}
            name="email"
            errorMessage={inputs.email.errorMessage}
            onChange={handleInputChange}
          />

          <Button label={t("send email")} type="submit" loading={isLoading} />
        </form>
      </div>

      <div className={styles.background} />
    </div>
  );
}

// Export the component for use elsewhere.
export default ForgottenPassword;
