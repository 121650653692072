// Defines a function to determine the appropriate payment path URL.
export const preparePaymentPath = (
  payment_path: string, // The suggested path for the payment URL.
  authorize_is_mine: string, // A flag indicating whether the authorization is owned by the user.
) => {
  // Determines the default path based on the authorization ownership.
  // If the user does not own the authorization, it defaults to "/make-payment/";
  // otherwise, it uses "/opaque-payment/".
  const defaultPath =
    authorize_is_mine === "false" ? "/make-payment/" : "/opaque-payment/";

  // Checks if the provided payment_path is not a string, is empty, or if the user does not own the authorization.
  // In any of these cases, it returns the defaultPath.
  if (
    typeof payment_path !== "string" || // Checks if payment_path is not a string.
    !payment_path || // Checks if payment_path is falsy (empty, null, etc.).
    authorize_is_mine === "false" // Checks if the authorization is not owned by the user.
  ) {
    return defaultPath;
  }

  // If payment_path is valid and the user owns the authorization,
  // it cleans up the payment_path by removing any spaces or slashes (/),
  // then wraps it with slashes to conform to URL path format.
  return `/${payment_path.replace(/[\s/]/g, "")}/`;
};
