// Import necessary dependencies.
import { Link } from "react-router-dom"; // For navigation to booking detail pages.
import classNames from "classnames"; // To dynamically apply CSS classes.
import { nanoid } from "@reduxjs/toolkit"; // For generating unique keys for list items.
import { useTranslation } from "react-i18next"; // Internationalization hook for translating content.
import dayjs from "dayjs";

// Import reusable components and utilities.
import { ReservationListInterface } from "../../../../../store/slices/reservationSlice"; // Type definition for booking list items.
import { BOOKING, routeParams } from "../../../../../utils/constants/routes"; // Routing utilities.
import { useTypedSelector } from "../../../../../store/store";

import SvgIcon from "../../../../shared/SvgIcon"; // For displaying icons.

// Import styles specific to this component.
import styles from "../../index.module.scss";

// Type definitions for the component props.
interface ContentProps {
  bookingList: ReservationListInterface[]; // Array of booking objects.
  sortBookingList: (type: string) => void; // Function to sort the booking list.
}

// Functional component definition accepting props.
function Content({ bookingList, sortBookingList }: ContentProps) {
  // Hook to access translation functionality.
  const { t } = useTranslation();

  const { date_format, pnr_column_name, cruise_column_name } = useTypedSelector(
    (state) => state.environment,
  );

  // Render the content of the booking list.
  return (
    <div className={styles.fieldsContainer}>
      {/* Header row displaying column titles with sorting options. */}
      <div className={styles.headerRow}>
        {/* Booking date column with sorting action. */}
        <div
          className={classNames(styles.column, styles.column_md)}
          style={{ cursor: "pointer" }}
          onClick={() => sortBookingList("booking_date")}>
          <span className={styles.uppercase}>{t("booked date")}</span>
          <div className={styles.svgBlock}>
            {/* Icons indicating sorting direction. */}
            <SvgIcon className={styles.arrowUp} type="triangle" />
            <SvgIcon className={styles.arrowDown} type="triangle" />
          </div>
        </div>

        {/* PNR column with no sorting action. */}
        <div className={classNames(styles.column, styles.column_sm)}>
          <span className={styles.uppercase}>{pnr_column_name}</span>
        </div>
        {/* Passenger name column with sorting action. */}
        <div
          className={classNames(styles.column, styles.column_md)}
          style={{ cursor: "pointer" }}
          onClick={() => sortBookingList("passenger_name")}>
          <span className={styles.uppercase}>{t("passenger name")}</span>
          <div className={styles.svgBlock}>
            {/* Icons indicating sorting direction. */}
            <SvgIcon className={styles.arrowUp} type="triangle" />
            <SvgIcon className={styles.arrowDown} type="triangle" />
          </div>
        </div>
        {/* Embarkation date column with sorting action. */}
        <div
          className={classNames(styles.column, styles.column_md)}
          style={{ cursor: "pointer" }}
          onClick={() => sortBookingList("embarkation_date")}>
          <span className={styles.uppercase}>{t("embark date")}</span>
          <div className={styles.svgBlock}>
            {/* Icons indicating sorting direction. */}
            <SvgIcon className={styles.arrowUp} type="triangle" />
            <SvgIcon className={styles.arrowDown} type="triangle" />
          </div>
        </div>
        {/* Cruise name column with no sorting action. */}
        <div className={classNames(styles.column, styles.column_lg)}>
          <span className={styles.uppercase}>{cruise_column_name}</span>
        </div>
        {/* Cruise name column with no sorting action. */}
        <div className={classNames(styles.column, styles.column_sm)}>
          <span className={styles.uppercase}>{t("status")}</span>
        </div>
        {/* Empty column for action icons. */}
        <div className={styles.actionColumn} />
      </div>
      {/* Mapping over the bookingList to render each booking as a row. */}
      {bookingList.map((booking) => {
        return (
          <div key={nanoid()} className={styles.contentRow}>
            {/* Columns for booking details. */}
            <div className={classNames(styles.column, styles.column_md)}>
              <span>{dayjs(booking.created).format(date_format)}</span>
            </div>

            <div className={classNames(styles.column, styles.column_sm)}>
              <span>{booking.pnr}</span>
            </div>

            <div className={classNames(styles.column, styles.column_md)}>
              <span>{booking.lead_lastname}</span>
            </div>

            <div className={classNames(styles.column, styles.column_md)}>
              <span>{dayjs(booking.embarkation_date).format(date_format)}</span>
            </div>

            <div className={classNames(styles.column, styles.column_lg)}>
              <span>{booking.cruise_name}</span>
            </div>

            <div className={classNames(styles.column, styles.column_sm)}>
              <span>{booking.status}</span>
            </div>

            {/* Action column with a link to the booking detail page. */}
            <div className={styles.actionColumn}>
              <Link to={routeParams(BOOKING, [["pnr", booking.pnr]])}>
                <SvgIcon className={styles.svgAction} type="externalLink" />
              </Link>
            </div>
          </div>
        );
      })}
    </div>
  );
}

// Export the component for use in other parts of the application.
export default Content;
