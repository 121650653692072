// Import useEffect hook for side effects, and router hooks for accessing URL parameters and search params.
import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

// Import Redux hooks for dispatching actions and selecting from the store.
import { useTypedDispatch, useTypedSelector } from "../../../store/store";
// Import a query hook for lazily initializing cruise data from an API.
import { useLazyInitCruiseQuery } from "../../../store/services/SearchService";
// Import the cruise interface and slice actions.
import { ICruise, Market } from "../../../store/slices/searchSlice";
import { RoomsState, updateRoomsState } from "../../../store/slices/roomsSlice";

// Import shared UI components.
import CustomBreadcrumbs from "../../shared/CustomBreadcrumbs";

// Import CSS module for styling.
import styles from "./index.module.scss";
import SearchBar from "../../base/SearchBar";

// Define constants for available market types.
const AVAILABLE_MARKETS: Array<keyof ICruise["markets"]> = [
  "inside",
  "outside",
  "balcony",
  "suite",
];

// Utility function to create a range array from start to end.
const createArrayRange = (start: number, end: number) => {
  const resultArray = [...Array(end - start + 1)].map(
    (_, index) => start + index,
  );

  return resultArray;
};

// Define the SearchLayout component, which arranges the search-related UI.
function SearchLayout({ children }: React.PropsWithChildren) {
  const dispatch = useTypedDispatch();
  // Hook for initiating a request to fetch cruise data.
  const [getCruise] = useLazyInitCruiseQuery();

  // Accessing dynamic parts of the URL and search parameters from the browser's location object.
  const { cruiseId } = useParams();
  const [searchParams] = useSearchParams();

  // Selectors for accessing cruise and rooms data from the Redux store.
  const { cruise } = useTypedSelector((state) => state.search);
  const { rooms } = useTypedSelector((state) => state.rooms);

  // Determine the total number of rooms from URL search parameters.
  const totalRooms = +(searchParams.get("rooms") ?? 0);
  const source = searchParams.get("source") ?? ""; // Source of the search from URL parameters.

  // Function to initialize cruise data using the cruiseId from the URL params.
  const initCruise = () => {
    getCruise({ cruiseId: cruiseId as string, source });
  };

  // Function to initialize the state for rooms/staterooms based on URL params and fetched cruise data.
  const initBlankStaterooms = () => {
    const roomsRange = createArrayRange(1, totalRooms);
    const defaultMarketType = AVAILABLE_MARKETS.find(
      (mKey) => (cruise?.markets[mKey] as Market).price !== "0",
    );

    const initialRoomsState: RoomsState = {
      rooms: {},
      cabins: undefined,
      isCabinsLoading: true,
    };

    roomsRange.forEach((num) => {
      const room = rooms?.[num] ?? {};

      const paramsFare = searchParams.get(`fare_${num}`);
      const paramsGrade = searchParams.get(`grade_${num}`);
      const paramsGuests = searchParams.get(`guests_${num}`);
      const paramsMarket = searchParams.get(`market_${num}`);

      const fare = cruise?.pricing.find((el) => el.rate_code === paramsFare);
      const grade = cruise?.ship.grades.find((el) => el.code === paramsGrade);
      const guestsNumber = paramsGuests ? +paramsGuests : undefined;
      const marketType = paramsMarket;

      initialRoomsState.rooms![num] = {
        ...room,
        fare: fare ?? cruise?.pricing.find((el) => el.lowest_fare),
        grade,
        guestsNumber,
        marketType: marketType ?? defaultMarketType,
      };
    });

    dispatch(updateRoomsState(initialRoomsState));
  };

  // Effect hook to initiate cruise data fetching when cruiseId changes.
  useEffect(() => {
    if (cruiseId) {
      initCruise();
    }
  }, [cruiseId]);

  // Effect hook to initialize room states when cruise data or totalRooms changes.
  useEffect(() => {
    if (cruise) {
      initBlankStaterooms();
    }
  }, [cruise, totalRooms]);

  // Rendering the layout which includes a search bar, custom breadcrumbs, and any children components passed to this layout.
  return (
    <div>
      <SearchBar />
      <main className={styles.container}>
        <section className={styles.content}>
          <CustomBreadcrumbs />

          <div>{children}</div>
        </section>
      </main>
    </div>
  );
}

// Export the SearchLayout component for use in the application.
export default SearchLayout;
