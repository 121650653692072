import { useRef } from "react"; // Importing useRef hook from React library

function useClientPosition() {
  // Defining a custom hook named useClientPosition
  const positionRef = useRef({ y: 0 }); // Initializing a ref to store client position, initially set to { y: 0 }

  const savePosition = () => {
    // Defining a function to save client position
    positionRef.current.y = window.scrollY; // Updating the y coordinate of positionRef with the current scroll position
  };

  const restorePosition = () => {
    // Defining a function to restore client position
    setTimeout(() => {
      // Using setTimeout to ensure the scroll happens after a short delay
      window.scrollTo({ top: positionRef.current.y }); // Scrolling the window to the saved y coordinate
    }, 500); // Delay of 500 milliseconds before restoring the scroll position
  };

  return { savePosition, restorePosition }; // Returning an object containing functions to save and restore client position
}

export default useClientPosition; // Exporting the custom hook useClientPosition
