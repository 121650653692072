// Import the LoadingIndicator component for visual feedback during loading states.
import LoadingIndicator from "../../shared/LoadingIndicator";

// Import SCSS module for styling.
import styles from "./index.module.scss";

// TypeScript interface defining props structure for LoadingContainer.
interface ILoadingContainerProps {
  isLoading: boolean; // Determines if loading indicator should be shown.
}

// Functional component to conditionally render children or a loading indicator based on isLoading prop.
function LoadingContainer({
  isLoading,
  children,
}: React.PropsWithChildren<ILoadingContainerProps>) {
  // If isLoading is true, render the LoadingIndicator component.
  if (isLoading) {
    return (
      <div className={styles.container}>
        <LoadingIndicator />
      </div>
    );
  }

  // If not loading, render the children passed to this component.
  return <>{children}</>;
}

// Export the component for use elsewhere in the application.
export default LoadingContainer;
