// Importing createAsyncThunk from Redux Toolkit for asynchronous actions.
import { createAsyncThunk } from "@reduxjs/toolkit";

// Importing constants and actions for showing toasts.
import TOAST from "../../utils/constants/toast";
import { showToast } from "../slices/toastSlice";

// Importing API and request interfaces from the Agency service.
import {
  AgencyAPI,
  ICreateAgencyRequest,
  ICreateAgentRequest,
} from "../services/AgencyService";

// Asynchronous action creator for retreiving an agency details.
export const getAgencyDetails = createAsyncThunk(
  "agency/getAgencyDetails",
  async (agency: string, { dispatch }) => {
    // Initiating the retreiving agency API call and destructuring the result.
    const { data } = await dispatch(
      AgencyAPI.endpoints.getAgencyDetails.initiate(agency),
    );

    // Return the response data.
    return data;
  },
);

// Asynchronous action creator for creating an agency.
export const createAgency = createAsyncThunk(
  "agency/createAgency",
  async (agency: ICreateAgencyRequest, { dispatch }) => {
    // Initiating the create agency API call and destructuring the result.
    const { status, data } = await dispatch(
      AgencyAPI.endpoints.createAgency.initiate(agency),
    );

    // If the request is fulfilled, dispatch a success toast.
    if (status === "fulfilled") {
      dispatch(
        showToast({
          type: TOAST.SUCCESS_TYPE,
          message: "Agency successfully created",
          duration: TOAST.DEFAULT_DURATION,
        }),
      );
    }

    // If the request is rejected, dispatch an error toast.
    if (status === "rejected") {
      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          message: "Something went wrong",
          duration: TOAST.DEFAULT_DURATION,
        }),
      );
    }

    // Return the response data.
    return data?.id;
  },
);

// Asynchronous action creator for creating an agent.
export const createAgent = createAsyncThunk(
  "agency/createAgent",
  async (agent: ICreateAgentRequest, { dispatch }) => {
    // Initiating the create agent API call and destructuring the result.
    const { status, data, error } = await dispatch(
      AgencyAPI.endpoints.createAgent.initiate(agent),
    );

    // If the request is fulfilled, dispatch a success toast.
    if (status === "fulfilled") {
      dispatch(
        showToast({
          type: TOAST.SUCCESS_TYPE,
          message: "Agent successfully created",
          duration: TOAST.DEFAULT_DURATION,
        }),
      );
    }

    // If the request is rejected, dispatch an error toast.
    if (status === "rejected") {
      if ("data" in error) {
        const errors = Object.values(error.data as Record<string, string[]>);

        errors.forEach((errorsArr) => {
          errorsArr.forEach((err) => {
            const message = err.charAt(0).toUpperCase() + err.slice(1);

            dispatch(
              showToast({
                type: TOAST.ERROR_TYPE,
                message,
                duration: TOAST.DEFAULT_DURATION,
              }),
            );
          });
        });
      } else {
        dispatch(
          showToast({
            type: TOAST.ERROR_TYPE,
            message: "Something went wrong",
            duration: TOAST.DEFAULT_DURATION,
          }),
        );
      }
    }

    // Return the response data.
    return data;
  },
);
