/**
 * Converts a numeric or string value to a formatted currency string.
 * @param {number | string} value - The numeric or string value to format as currency.
 * @param {string} currency - The currency code (e.g., "USD", "EUR").
 * @returns {string} The formatted currency string.
 */
export const currencyToFormat = (
  value: number | string,
  currency: string = "USD",
) =>
  Number(value).toLocaleString("en-US", {
    style: "currency",
    currency,
    minimumFractionDigits: 2,
  });
