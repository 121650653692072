import { createSlice, PayloadAction } from "@reduxjs/toolkit"; // Import necessary modules

// Define interface for modal state
export interface ModalState {
  type: string; // Type of the modal
  data?: any; // Data associated with the modal
  size?: "sm" | "md" | "lg";
  scrollY?: number;
}

// Define initial state for the modal
const initialState: ModalState = {
  type: "", // Initial type is empty string
  data: null, // Initial data is null
  size: "sm",
  scrollY: 0,
};

// Create slice for managing modal state
const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal(state: ModalState, action: PayloadAction<ModalState>) {
      // Update modal type and data with the payload
      state.type = action.payload.type;
      state.size = action.payload.size;
      state.data = action.payload.data;
    },
    hideModal(state) {
      // Reset modal type to empty string to hide the modal
      state.type = "";
    },
    setScrollY(state: ModalState, action: PayloadAction<number>) {
      state.scrollY = action.payload;
    },
  },
});

// Export action creators and reducer
export const { showModal, hideModal, setScrollY } = modalSlice.actions;

export default modalSlice.reducer;
