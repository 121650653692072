import { useMemo } from "react"; // Importing necessary hooks from React
import classNames from "classnames"; // Utility for conditionally joining classNames.
import { useTranslation } from "react-i18next"; // Importing the useTranslation hook for language translation
import { useSearchParams } from "react-router-dom"; // Importing the useSearchParams hook from React Router DOM

import { useTypedSelector } from "../../../../../store/store"; // Importing the useTypedSelector hook from the Redux store
import { Room } from "../../../../../store/slices/roomsSlice"; // Import Room type
import { Price } from "../../../../../store/slices/searchSlice"; // Import Price type
import { parseMarkdownWithLink } from "../../../../../utils/helpers/parseMarkdown";

import styles from "./index.module.scss"; // Importing the CSS module for styling

// Props interface for FareCodes component
interface IFareCodesProps {
  room?: Room;
  handleChoseRate: (stateroom: Room) => void;
}

/**
 * FareCodes component displays a list of fare codes for a given room and allows the user to select one.
 * @param {Room} room - The room for which fare codes are being displayed.
 * @param {Function} handleChoseRate - Function to handle the selection of a fare code.
 * @returns {JSX.Element} FareCodes component JSX elements.
 */
function FareCodes({ room, handleChoseRate }: IFareCodesProps) {
  const { t } = useTranslation(); // Translation function

  const [searchParams] = useSearchParams(); // Get search parameters from URL

  const { cruise } = useTypedSelector((state) => state.search); // Get cruise data from Redux store

  const isRateChoseAvailable = +(searchParams.get("room") ?? 1) <= 1; // Check if selecting a fare code is available

  // Sort fare codes with the lowest fare first
  const sortFare = useMemo(() => {
    const fareArrCopy = cruise?.pricing;
    const findTrue = fareArrCopy?.find((item) => item.lowest_fare);
    const findFalse = fareArrCopy?.filter((item) => !item.lowest_fare) ?? [];

    return findTrue ? [findTrue, ...findFalse] : findFalse;
  }, [cruise]);

  // Handle click on a fare code
  const handleRateClick = (fare: Price) => () => {
    if (isRateChoseAvailable) {
      const updatedStateroom = structuredClone({ ...room, fare });

      handleChoseRate(updatedStateroom);
    }
  };

  // Render the FareCodes component
  return (
    // Container for the FareCodes component
    <div className={styles.container}>
      {/* Title for fare code selection */}
      <p className={styles.title}>{t("select a fare code")}</p>

      {/* List of fare codes */}
      <div
        className={classNames(styles.items, {
          [styles.items_short]: (cruise?.pricing ?? []).length <= 1,
        })}>
        {sortFare.map((item) => {
          const isChosen = item?.rate_code === room?.fare?.rate_code;

          return (
            // Individual fare code item
            <div key={item?.rate_code} className={styles.item}>
              {/* Header for each fare code */}
              <div
                className={classNames(styles.itemHeader, {
                  [styles.itemHeader_active]: isChosen,
                })}>
                <p>
                  {t("fare:")} {item.rate_name}
                </p>
              </div>

              {/* Body of fare code item */}
              <div className={styles.itemBody}>
                {/* Display fare code name if description is empty */}
                {(!item.rate_description ||
                  item.rate_description.length === 0) && (
                  <p>{item?.rate_name}</p>
                )}
                {/* Display fare code description */}
                {item.rate_description &&
                  item.rate_description.length !== 0 && (
                    <>
                      <ul className={styles.itemBodyList}>
                        {item.rate_description.map((desc: string) => {
                          const parsedDesc = parseMarkdownWithLink(desc);

                          return (
                            <li key={desc} className={styles.itemBodyDesc}>
                              {parsedDesc.map((parsed) => {
                                return parsed.url ? (
                                  <a
                                    key={parsed.text}
                                    href={parsed.url}
                                    className={styles.link}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {parsed.text}
                                  </a>
                                ) : (
                                  <span key={parsed.text}>{parsed.text}</span>
                                );
                              })}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
              </div>

              {/* Footer of fare code item */}
              <div
                className={classNames(styles.itemFooter, {
                  [styles.itemFooter_unchosen]: !isChosen,
                  [styles.itemFooter_disabled]: !isRateChoseAvailable,
                })}>
                {/* Show "selected" or "select" text based on whether fare code is chosen */}
                <p {...(!isChosen ? { onClick: handleRateClick(item) } : {})}>
                  {isChosen ? t("selected") : t("select")}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

// Export the FareCodes component
export default FareCodes;
