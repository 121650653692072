// Importing the classNames function for convenient class name concatenation
import classNames from "classnames";

// BASE ICON IMPORTS
// Importing SVG icons as React components for reusable icon components
import { ReactComponent as Menu } from "../../../assets/icons/menu.svg";
import { ReactComponent as Cross } from "../../../assets/icons/cross.svg";
import { ReactComponent as Plus } from "../../../assets/icons/plus.svg";
import { ReactComponent as Minus } from "../../../assets/icons/minus.svg";
import { ReactComponent as Loupe } from "../../../assets/icons/loupe.svg";
import { ReactComponent as Checked } from "../../../assets/icons/checked.svg";
import { ReactComponent as Unchecked } from "../../../assets/icons/unchecked.svg";
import { ReactComponent as EyeVisible } from "../../../assets/icons/eye-visible.svg";
import { ReactComponent as EyeHidden } from "../../../assets/icons/eye-hidden.svg";
import { ReactComponent as Play } from "../../../assets/icons/play.svg";
import { ReactComponent as Upload } from "../../../assets/icons/upload.svg";
import { ReactComponent as Triangle } from "../../../assets/icons/triangle.svg";
import { ReactComponent as ExternalLink } from "../../../assets/icons/external-link.svg";
import { ReactComponent as Plane } from "../../../assets/icons/plane.svg";
import { ReactComponent as Up } from "../../../assets/icons/up.svg";
import { ReactComponent as Down } from "../../../assets/icons/down.svg";

// Importing custom styles for the component
import styles from "./index.module.scss";

// Defining a base object for easy reference to our icons by name
const BASE: Record<string, React.FC<React.SVGProps<SVGSVGElement>>> = {
  menu: Menu,
  cross: Cross,
  plus: Plus,
  minus: Minus,
  loupe: Loupe,
  checked: Checked,
  unchecked: Unchecked,
  eyeVisible: EyeVisible,
  eyeHidden: EyeHidden,
  play: Play,
  upload: Upload,
  triangle: Triangle,
  externalLink: ExternalLink,
  plane: Plane,
  up: Up,
  down: Down,
};

// Extending BASE with any additional icons or overriding existing ones
const SVG_BY_TYPE = {
  ...BASE,
};

// Defining the props interface for our SvgIcon component
interface ISvgIconProps {
  type: keyof typeof SVG_BY_TYPE; // The icon type, matching keys in SVG_BY_TYPE
  className?: string; // Optional custom class name for additional styling
  onClick?: () => void; // Optional click event handler
}

// The SvgIcon component definition
function SvgIcon({ type, className, onClick }: ISvgIconProps) {
  // Determining which icon to render based on the 'type' prop
  const CustomSvg = SVG_BY_TYPE[type] || "svg";

  // Rendering the SVG component with optional className and onClick props
  return (
    <CustomSvg
      className={classNames(styles.icon, className)} // Combining predefined and custom class names
      onClick={onClick} // Assigning the click event handler, if provided
    />
  );
}

// Default props for the SvgIcon component
SvgIcon.defaultProps = {
  className: "", // No additional class name by default
  onClick: () => {}, // No operation for click by default
};

// Exporting SvgIcon as the default export of this module
export default SvgIcon;
