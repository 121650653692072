import i18n from "../i18n";

const { t } = i18n;

const MODAL_TYPES = {
  ITINERARY: t("ITINERARY"),
  IMAGES: t("IMAGES"),
  STATEROOMS: t("STATEROOMS"),
  DECK_PLANS: t("DECK PLANS"),
  CANCELLATION_POLICY: t("CANCELLATION POLICY"),
};

const MODAL = { MODAL_TYPES };

export default MODAL;
