// Hook for programmatically navigating between routes.
import { useNavigate } from "react-router-dom";
// Utility for conditionally applying class names.
import classNames from "classnames";
// Hook for accessing internationalization functionalities.
import { useTranslation } from "react-i18next";

// Custom hook for selecting state slices from the Redux store.
import { useTypedSelector } from "../../../store/store";
// Constants defining navigation targets for the agent's home page.
import AGENT_HOME from "../../../utils/constants/agentHome";

// Styles specific to the AgentHome component.
import styles from "./index.module.scss";
// Reusable component for displaying SVG icons.
import SvgIcon from "../../shared/SvgIcon";
import { useMemo } from "react";
import { ITile } from "../../../store/slices/environmentSlice";

// The main functional component for the agent's homepage.
function AgentHome() {
  // Hook to programmatically navigate to different routes.
  const navigate = useNavigate();
  // Hook to access the translation function.
  const { t } = useTranslation();

  // Retrieving the agent's name from the Redux store.
  const { name } = useTypedSelector((state) => state.session);
  const {
    welcome_title,
    start_booking_section_background,
    current_bookings_section_background,
    previous_bookings_section_background,
    agent_home_tiles,
  } = useTypedSelector((state) => state.environment);

  const parsedTiles: ITile[] = useMemo(() => {
    const parsed = JSON.parse(agent_home_tiles);

    if (Array.isArray(parsed)) {
      return parsed;
    }

    return [];
  }, [agent_home_tiles]);

  // Handler for navigating to a specific path. It's curried to delay execution until called with an event (e.g., onClick).
  const handleNavigate = (path: string) => () => {
    // If a valid path is provided, navigate to it.
    if (path) {
      navigate(path);
    }
  };

  const getTileBackground = (tileKey: "start" | "current" | "previous") => {
    switch (tileKey) {
      case "start": {
        return start_booking_section_background;
      }

      case "current": {
        return current_bookings_section_background;
      }

      default: {
        return previous_bookings_section_background;
      }
    }
  };

  // Render the agent's home layout.
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {/* Display a personalized greeting to the agent. */}
        <div className={styles.greetingContainer}>
          <h1 className={styles.title}>{`${t("hello")} ${name}`}</h1>

          <p className={styles.subTitle}>{welcome_title}</p>
        </div>
        {/* Container for interactive tiles, each representing a different section or functionality. */}
        <div className={styles.tiles}>
          {/* Dynamically generate tiles based on AGENT_HOME.TILES data. */}
          {Object.entries(AGENT_HOME.TILES).map(([tileKey, tile]) => {
            return (
              <div
                key={tileKey}
                // Clicking a tile navigates to its corresponding path.
                onClick={handleNavigate(tile.path)}
                // Apply styles conditionally based on the tileKey and whether the tile is disabled.
                className={classNames(styles.tile, {
                  [styles.tile_disabled]: !tile.path,
                })}
                style={{
                  background: `url(${getTileBackground(tileKey as "start" | "current" | "previous")}) center/cover`,
                }}>
                <div className={styles.tile_bottom}>
                  <div className={styles.tile_text}>
                    {/* Title and description for the tile. */}
                    <p className={styles.tile_title}>{tile.title()}</p>

                    <p className={styles.tile_description}>
                      {tile.description()}
                    </p>
                  </div>

                  {/* A play icon to indicate actionability. */}
                  <div className={styles.tile_playContainer}>
                    <SvgIcon type="play" />
                  </div>
                </div>

                {/* Background decoration for the tile, colored based on the tile data. */}
                <div
                  className={classNames(
                    styles.tile_bg,
                    styles[`tile_bg_${tile.color}`],
                  )}
                />
              </div>
            );
          })}
          {parsedTiles.map((tile) => {
            return (
              <a
                key={tile.link_url}
                href={tile.link_url}
                target="_blank"
                rel="noreferrer"
                className={styles.tile}
                style={{
                  background: `url(${tile.background_image}) center/cover`,
                  textDecoration: "none",
                }}>
                <div className={styles.tile_bottom}>
                  <div className={styles.tile_text}>
                    {/* Title and description for the tile. */}
                    <p className={styles.tile_title}>{tile.title}</p>

                    <p className={styles.tile_description}>
                      {tile.description_text}
                    </p>
                  </div>

                  {/* A play icon to indicate actionability. */}
                  <div className={styles.tile_playContainer}>
                    <SvgIcon type="play" />
                  </div>
                </div>

                {/* Background decoration for the tile, colored based on the tile data. */}
                <div
                  className={styles.tile_bg}
                  style={{
                    backgroundColor: tile.background_color,
                    opacity: 0.65,
                  }}
                />
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
}

// Exporting AgentHome to be used within the application.
export default AgentHome;
