import { createSlice } from "@reduxjs/toolkit"; // Import necessary modules

import {
  IGetShipContentResponse,
  ContentAPI,
} from "../services/ContentService"; // Import IGetShipContentResponse type from contentService

// Define interface for content state
export interface ContentState {
  ship: IGetShipContentResponse;
}

// Define initial state for the content
const initialState: ContentState = {
  ship: {
    grades: [],
  },
};

// Create slice for managing content state
const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      ContentAPI.endpoints.getShipContent.matchFulfilled,
      (state, action) => {
        state.ship = action.payload;
      },
    );
  },
});

export default contentSlice.reducer;
