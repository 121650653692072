import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../../../../../store/store";

import {
  getCancellationPolicyDaysRange,
  getCancellationPolicyValue,
} from "../../../../../utils/helpers/cancellationPolicy";

import styles from "./index.module.scss";

function CancellationPolicyModal() {
  const { t } = useTranslation();

  const { raw_cancellation_policy, currency } = useTypedSelector(
    (state) => state.prices,
  );

  return (
    <div className={styles.container}>
      <div className={styles.tableContainer}>
        <div>
          <div className={styles.tableWrapper}>
            <table>
              <thead>
                <tr>
                  <th>{t("days before departure")}</th>
                  <th>{t("value")}</th>
                </tr>
              </thead>

              <tbody>
                {(raw_cancellation_policy ?? []).map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <p>
                          {getCancellationPolicyDaysRange(
                            item?.days_before_departure,
                          )}
                        </p>
                      </td>

                      <td>
                        <p>
                          {getCancellationPolicyValue({
                            ...item,
                            currency,
                          })}
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CancellationPolicyModal;
