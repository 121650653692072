import { useState, useEffect } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import { useTypedSelector } from "../../../store/store";

import DropDown from "../DropDown";

import "./index.css";

function PaginationComponent({ data, setData }: { data: any; setData: any }) {
  const { pagination_type, pagination_count } = useTypedSelector(
    (state) => state.environment,
  );
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItems, setCurrentItems] = useState([]);

  useEffect(() => {
    setCurrentItems(
      data.slice((currentPage - 1) * pageSize, currentPage * pageSize),
    );
  }, [pageSize, currentPage, data]);

  const handleLoadMore = () => {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    setPageSize(pageSize + pagination_count);
  };

  useEffect(() => {
    setData(currentItems);
  }, [currentItems]);

  const handlePageSize = (event: any) => {
    const pageSize = event || 10;

    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const handleCurrentPage = (page: number) => {
    setCurrentPage(page);
  };

  const prepareShowing = () => {
    const from = (currentPage - 1) * pageSize + 1;
    const to = Math.min(currentPage * pageSize, data.length);
    const of = data.length;

    return `Showing ${from} to ${to} of ${of}`;
  };

  const calculatePaginationRange = () => {
    const totalPages = Math.ceil(data.length / pageSize);
    const halfRange = Math.floor(5 / 2);
    let start = Math.max(1, currentPage - halfRange);

    const end = Math.min(start + 4, totalPages);

    if (end === totalPages) {
      start = Math.max(1, totalPages - 4);
    }
    return { start, end };
  };

  const { start, end } = calculatePaginationRange();

  const renderPaginationItems = () => {
    const items = [];

    for (let i = start; i <= end; i++) {
      items.push(
        <PaginationItem key={i} active={i === currentPage}>
          <PaginationLink onClick={() => handleCurrentPage(i)}>
            {i}
          </PaginationLink>
        </PaginationItem>,
      );
    }
    return items;
  };

  return (
    <div className="w-100 d-flex justify-content-between mt-3">
      {pagination_type === "more" ? (
        <button
          style={{
            opacity: data.length <= currentItems.length ? 0.4 : 1,
            pointerEvents: data.length <= currentItems.length ? "none" : "auto",
          }}
          disabled={data.length <= currentItems.length}
          className="more-button"
          onClick={handleLoadMore}>
          Load More
        </button>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center gap-3">
            <DropDown
              onSelection={handlePageSize}
              className="btn-secondary"
              defaultValue={10}
              hideEmptyValue
              data={[
                { code: 10, name: 10 },
                { code: 20, name: 20 },
                { code: 50, name: 50 },
              ]}
            />
            <p className="mb-0">{prepareShowing()}</p>
          </div>
          <Pagination>
            <PaginationItem>
              <PaginationLink
                previous
                onClick={() => {
                  if (currentPage > 1) {
                    handleCurrentPage(currentPage - 1);
                  }
                }}
              />
            </PaginationItem>
            {renderPaginationItems()}
            <PaginationItem>
              <PaginationLink
                next
                onClick={() => {
                  const totalPages = Math.ceil(data.length / pageSize);

                  if (currentPage < totalPages) {
                    handleCurrentPage(currentPage + 1);
                  }
                }}
              />
            </PaginationItem>
          </Pagination>
        </>
      )}
    </div>
  );
}

export default PaginationComponent;
