/**
 * Creates an array containing a range of numbers from start to end (inclusive).
 * @param {string | number} range - The starting number of the range.
 * @param {number} end - The ending number of the range.
 * @returns {number[]} An array containing numbers from start to end.
 */
export const createArrayRange = (range: string | number, end?: number) => {
  let start = 0;

  [start, end] =
    typeof range === "string"
      ? range.split("-").map(Number)
      : [end ? range : 0, end ?? start];

  return [...Array(end - start + 1)].map((_, index) => start + index);
};
