const agencyRegistrationRules = {
  sales_area: { one_of: [null] },
  name: ["required", "trim", "string"],
  address: ["required", "trim", "string"],
  city: ["required", "trim", "string"],
  state: ["required", "trim", "string"],
  zip: ["required", "trim", "string"],
  country: ["required", "trim", "string"],
  telephone: ["required", "trim", "string"],
  website: ["trim", "url"],
  regulatory_type: ["required", "trim", "string"],
  regulatory: ["required", "trim", "string"],
  consortia: ["required", "trim", "string"],
  market: ["required", "trim", "string"],
  title: ["required", "trim", "string"],
  firstName: ["required", "trim", "string"],
  surname: ["required", "trim", "string"],
  agent_telephone: ["required", "trim", "string"],
  position: ["required", "trim", "string"],
  email: ["required", "trim", "email"],
  b2b_password: ["required", "trim", "string", { min_length: 8 }],
  confirm_b2b_password: [
    "required",
    "trim",
    "string",
    { equal_to_field: "b2b_password", min_length: 8 },
  ],
};

export default agencyRegistrationRules;
