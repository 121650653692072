// Importing createAsyncThunk utility from Redux Toolkit for creating asynchronous actions.
import { createAsyncThunk } from "@reduxjs/toolkit";

// Importing services for session management and point of sale interactions.
import { SessionApi } from "../services/SessionService";

import { PosAPI } from "../services/PosService";
// Importing the type for the root state of the Redux store.
import { RootState } from "../store";
// Importing constants for toast notifications.
import TOAST from "../../utils/constants/toast";
// Importing the action creator for showing toast notifications.
import { showToast } from "../slices/toastSlice";

// Retrieving developer API credentials from environment variables.
const DEV_API_USERNAME = process.env.REACT_APP_DEV_API_USERNAME as string;
const DEV_API_PASSWORD = process.env.REACT_APP_DEV_API_PASSWORD as string;

// Creating an asynchronous action to initialize the API token.
export const initApiToken = createAsyncThunk(
  "session/initApiToken",
  async (_, { dispatch }) => {
    // Making an API call to initialize the API token with the developer credentials.
    const { data } = await dispatch(
      SessionApi.endpoints.initApiToken.initiate({
        username: DEV_API_USERNAME,
        password: DEV_API_PASSWORD,
      }),
    );

    // Dispatching an error toast notification if the data is not received (implying wrong credentials).
    if (!data) {
      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          message: "Wrong credentials",
          duration: TOAST.DEFAULT_DURATION,
        }),
      );
    }

    // Returning the data received from the API call.
    return data;
  },
);

// Creating an asynchronous action to initialize the POS session.
export const initPosSession = createAsyncThunk(
  "session/initPosSession",
  async (_, { getState, dispatch }) => {
    // Retrieving agent and agency details from the state.
    const { agent, agency } = (getState() as RootState).session;
    // Making an API call to initialize the POS session with the agent and agency details.
    const { data } = await dispatch(
      PosAPI.endpoints.initPosSession.initiate({ agent, agency }),
    );

    // Dispatching toast notifications based on whether the data is received or not.
    if (!data) {
      // Error toast if data is not received.
      dispatch(
        showToast({
          type: TOAST.ERROR_TYPE,
          message: "Sign in failed",
          duration: TOAST.DEFAULT_DURATION,
        }),
      );
    } else if (data) {
      // Success toast if data is received.
      dispatch(
        showToast({
          type: TOAST.SUCCESS_TYPE,
          message: "Successfully signed in",
          duration: TOAST.DEFAULT_DURATION,
        }),
      );
    }

    // Returning the data received from the API call.
    return data;
  },
);

// Creating an asynchronous action to destroy the session.
export const destroySession = createAsyncThunk("session/destroySession", () => {
  // Clearing session-related cookies.
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  }

  // Clearing local and session storage.
  localStorage.clear();
  sessionStorage.clear();
});
