import { nanoid } from "@reduxjs/toolkit"; // Importing nanoid from Redux Toolkit
import classNames from "classnames";

import Carousel from "react-bootstrap/Carousel"; // Importing Carousel component from react-bootstrap

import styles from "./index.module.scss"; // Importing styles for the CustomCarousel component
import "./index.css"; // Importing CSS for additional styling

type IImageItem = string; // Defining a type for each image item in the carousel

// Defining props interface for the CustomCarousel component
interface ICarouselProps {
  items: IImageItem[]; // Array of image URLs for the carousel items
  className?: string; // Optional additional class name for the carousel
  slideClassName?: string; // Optional additional class name for the carousel
}

/**
 * CustomCarousel component.
 *
 * @param {string[]} items - Required array of image URLs for the carousel items.
 * @param {string} className - Optional additional class name for the carousel.
 * @returns {JSX.Element} CustomCarousel element.
 */
function CustomCarousel({ items, className, slideClassName }: ICarouselProps) {
  return (
    <Carousel
      className={classNames(styles.container, className)}
      indicators={false}
      interval={null}
      prevIcon={
        <div className={styles.arrowContainer}>
          <span className="carousel-control-prev-icon" />
        </div>
      }
      nextIcon={
        <div className={styles.arrowContainer}>
          <span className="carousel-control-next-icon" />
        </div>
      }>
      {items.map((slide) => {
        return (
          <Carousel.Item key={nanoid()}>
            <img
              src={slide}
              className={classNames(styles.slide, slideClassName)}
            />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

// Default props for the CustomCarousel component
CustomCarousel.defaultProps = {
  className: "",
};

// Exporting the CustomCarousel component
export default CustomCarousel;
