// Import necessary dependencies
import classNames from "classnames"; // Importing classNames library for managing CSS class names

import SvgIcon from "../SvgIcon"; // Importing SvgIcon component

import styles from "./index.module.scss"; // Importing CSS module styles
import { CSSProperties } from "react";

// Define props interface for SvgButton component
interface ISvgButtonProps {
  icon: string;
  className?: string;
  onClick: () => void;
  style?: CSSProperties;
}

/**
 * SvgButton component renders a button with an SVG icon.
 *
 * @param {string} icon - The name of the SVG icon to render.
 * @param {string} className - Additional CSS class names to apply.
 * @param {function} onClick - Click event handler for the button.
 * @param {CSSProperties} style - Click event handler for the button.
 * @returns {JSX.Element} - JSX button element with SVG icon.
 */
function SvgButton({ icon, className, onClick, style }: ISvgButtonProps) {
  return (
    <button
      style={style}
      type="button"
      tabIndex={-1}
      aria-label={icon}
      className={classNames(styles.button, className)}
      onClick={onClick}>
      {/* SvgIcon component for rendering icon */}
      <SvgIcon type={icon} className={styles.icon} />
    </button>
  );
}

// Default props for SvgButton component
SvgButton.defaultProps = {
  className: "", // Default empty className
};

// Export SvgButton component
export default SvgButton;
