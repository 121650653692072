// Import useState hook for managing state within the component.
import { useState } from "react";

// Import the type definition for layout types.
import { ILayoutType } from "../../../utils/constants/routes";

// Import layout components for different sections of the application.
import AuthLayout from "../Auth";
import MainLayout from "../Main";
import SearchLayout from "../Search";

// Import base components that are common across layouts.
import Header from "../../base/Header";
import SideMenu from "../../base/SideMenu";
import Footer from "../../base/Footer";

// Import CSS module styles specific to this component.
import styles from "./index.module.scss";

// A mapping object that associates layout types with their corresponding React functional components.
const LAYOUTS_BY_TYPE: Record<
  ILayoutType,
  React.FC<React.PropsWithChildren>
> = {
  auth: AuthLayout, // Layout for authentication-related pages.
  main: MainLayout, // Layout for the main content area of the application.
  search: SearchLayout, // Layout for search-related functionality.
};

// Interface for the component props.
interface IRootLayoutProps {
  type: ILayoutType; // The type of layout to be used, derived from the ILayoutType.
}

// The main component function, defining the root layout structure based on the passed `type`.
function RootLayout({
  type, // The type of layout.
  children, // Child components to be rendered within the selected layout.
}: React.PropsWithChildren<IRootLayoutProps>) {
  // State to control the visibility of the side menu.
  const [isMenuVisible, setIsMenuVissible] = useState(false);

  // Function to toggle the side menu's visibility.
  const handleMenuToggle = (isVisible: boolean) => () => {
    setIsMenuVissible(isVisible);
  };

  // Determine the layout component to use based on the `type` prop.
  const Layout = LAYOUTS_BY_TYPE[type];

  // Render the structure of the root layout.
  return (
    <div>
      {/* Render the header component, passing in props for menu visibility and the toggle handler. */}
      <Header
        isMenuVisible={isMenuVisible}
        onMenuToggle={handleMenuToggle(!isMenuVisible)}
      />

      {/* Render the side menu component with props for visibility and the close handler. */}
      <SideMenu isVisible={isMenuVisible} onClose={handleMenuToggle(false)} />

      {/* Main content area, wrapped in a main tag for semantic purposes, containing the selected layout component. */}
      <main className={styles.container}>
        <div className={styles.content}>
          <Layout>{children}</Layout>
        </div>
      </main>

      {/* Render the footer component. */}
      <Footer />
    </div>
  );
}

// Export RootLayout for use as a wrapper around the app's content, providing a consistent structure across different layout types.
export default RootLayout;
