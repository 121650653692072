import { createSlice } from "@reduxjs/toolkit"; // Import createSlice function from the Redux Toolkit

// Import necessary types and services from the AgencyService module
import { CabinSelectAPI } from "../services/CabinSelectService";

interface InitialStateType {
  sailing: {
    sailing_code: string;
    cruise: {
      code: string;
      name: string;
    };
  };
  ship: { code: string; name: string };
  rate: { code: string; name: string };
  currency: string;
  total_price: string;
  guests: Array<{
    guest: number;
    total_price: string;
    total_fare: string;
    gft: string;
    nccf: string;
    discount: string;
  }>;
  payment_schedule: Array<{ date: string; currency: string; amount: string }>;
  cancellation_policy: Array<{ days_before_departure: number; policy: string }>;
  raw_cancellation_policy: Array<{
    price_type: string;
    type: string;
    value: number;
    days_before_departure: number;
  }>;
}

const initialState: InitialStateType = {
  sailing: {
    sailing_code: "",
    cruise: {
      code: "",
      name: "",
    },
  },
  ship: {
    code: "",
    name: "",
  },
  rate: {
    code: "",
    name: "",
  },
  currency: "USD",
  total_price: "",
  guests: [],
  payment_schedule: [],
  cancellation_policy: [],
  raw_cancellation_policy: [],
};
// Create the agencySlice using createSlice function
const pricesSlice = createSlice({
  name: "prices", // Name of the slice
  initialState, // Initial state
  reducers: {}, // Reducer functions (currently empty)
  extraReducers: (builder) => {
    // Add extra reducers for handling actions dispatched by the CabinSelectAPI
    builder.addMatcher(
      // Handle fulfilled action for initializing consortia
      CabinSelectAPI.endpoints.getPricing.matchFulfilled,
      (state, action) => {
        if (!action.payload) {
          return;
        }

        // Update the state with the payload received from the action
        state.cancellation_policy = action.payload?.cancellation_policy || "";
        state.currency = action.payload.currency;
        state.guests = action.payload.guests;
        state.payment_schedule = action.payload.payment_schedule;
        state.rate = action.payload.rate;
        state.sailing = action.payload.sailing;
        state.ship = action.payload.ship;
        state.total_price = action.payload.total_price;

        state.raw_cancellation_policy =
          action.payload?.raw_cancellation_policy || "";
      },
    );
  },
});

// Export the reducer function generated by the pricesSlice
export default pricesSlice.reducer;
