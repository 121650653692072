// Importing useNavigate hook from react-router-dom for navigation.
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

// Importing constants for routes.
import { ROOT } from "../../../../../utils/constants/routes";
import { useTypedSelector } from "../../../../../store/store";

// Importing shared Button component for UI.
import Button from "../../../../shared/Button";

// Importing CSS module for styling.
import styles from "./index.module.scss";
// Importing the translation function from i18next for internationalization.

// Defining the SuccessfullyReset component.
function SuccessfullyReset() {
  // Using the useNavigate hook for redirection.
  const navigate = useNavigate();

  const { auth_page_background } = useTypedSelector(
    (state) => state.environment,
  );

  // Returning the JSX of the component.
  return (
    <div
      className={styles.container}
      style={{ background: `url(${auth_page_background}) center/cover` }}>
      {/* Container div for the content. */}
      <div className={styles.content}>
        {/* Paragraph for the title with dynamic styling. */}
        <p className={styles.title}>password successfully reset</p>

        {/* Paragraph for the subtitle with dynamic content and styling. */}
        <p className={styles.subtitle}>
          {t(`You’ve successfully reset your password. Click the login button below
          to use your new password.`)}
        </p>

        {/* Button component with an event handler for redirection. */}
        <Button label={t("login")} onClick={() => navigate(ROOT)} />
      </div>

      {/* Additional div for background styling. */}
      <div className={styles.background} />
    </div>
  );
}

// Exporting the SuccessfullyReset component as default.
export default SuccessfullyReset;
