import { PayloadAction, createSlice } from "@reduxjs/toolkit"; // Import necessary modules

import { PaymentScheduleItem, Rooms } from "./roomsSlice"; // Import types from roomsSlice

// Define interface for pricing state
export interface PricingState {
  payment_schedule: Record<string, PaymentScheduleItem>; // Payment schedule object with date keys and PaymentScheduleItem values
  amount: string; // Total amount
  currency: string; // Currency
  due_date: string; // Due date
}

// Define initial state for pricing
const initialState: PricingState = {
  payment_schedule: {}, // Initial payment schedule is empty
  amount: "", // Initial amount is empty string
  currency: "USD", // Initial currency
  due_date: "", // Initial due date is empty string
};

// Create slice for managing pricing state
const pricingSlice = createSlice({
  name: "pricing",
  initialState,
  reducers: {
    setPricing: (
      state: PricingState,
      action: PayloadAction<Rooms | undefined>, // Payload action with Rooms type or undefined
    ) => {
      if (!action.payload) {
        return;
      }

      // Initialize an output object for payment schedule
      const output: Record<string, PaymentScheduleItem> = {};
      const clonedRooms = structuredClone(action.payload);

      for (const roomKey in clonedRooms) {
        const pricing = clonedRooms[+roomKey].pricing;

        if (pricing?.payment_schedule) {
          pricing.payment_schedule.forEach(
            (transaction: PaymentScheduleItem) => {
              const { date, amount } = transaction; // Get pricing information for the room

              // If the date key doesn't exist in the output, initialize it with the transaction
              if (!output[date]) {
                output[date] = transaction;
              } else {
                output[date].amount = `${
                  Number(output[date].amount) + Number(amount)
                }`;
              }
            },
          );
        }
      }
      state.payment_schedule = output; // Update payment schedule in state with the output
    },
  },
});

// Export action creators and reducer
export const { setPricing } = pricingSlice.actions;

export default pricingSlice.reducer;
