// Importing necessary React hooks and the CSS module for styling.
import { useMemo, useState } from "react";
// Import hook for internationalization.
import { useTranslation } from "react-i18next";
// Importing dayjs for date manipulation.
import dayjs from "dayjs";
// Utility for conditionally joining classNames together.
import classNames from "classnames";
// Utility to generate unique IDs.
import { nanoid } from "@reduxjs/toolkit";

// Custom hook to access the typed Redux store.
import { useTypedDispatch, useTypedSelector } from "../../../store/store";
// Helper function to format currency values.
import { currencyToFormat } from "../../../utils/helpers/currency";
import { showModal } from "../../../store/slices/modalSlice";
import MODAL from "../../../utils/constants/modal";

// Reusable Collapsible component for showing/hiding content.
import Collapsible from "../../shared/Collapsible";

// Importing module CSS for styling components.
import styles from "./index.module.scss";

// Component for displaying booking details.
function BookingDetails() {
  const dispatch = useTypedDispatch();
  // Hook for handling translations.
  const { t } = useTranslation();

  // Extracting specific slices of state using a typed selector hook.
  const { cruise } = useTypedSelector((state) => state.search);
  const { rooms } = useTypedSelector((state) => state.rooms);
  const { payment_schedule } = useTypedSelector((state) => state.pricing);
  const { cancellation_policy } = useTypedSelector((state) => state.prices);
  const { commission_level } = useTypedSelector((state) => state.agency);

  const { date_format, show_cancellation_policy } = useTypedSelector(
    (state) => state.environment,
  );

  // State hook for managing the expand/collapse state of the component.
  const [detailsExpanded, setDetailsExpanded] = useState(true);
  const [agentExpanded, setAgentExpanded] = useState(true);

  // useMemo hook to calculate the total price of all rooms only when 'rooms' object changes.
  const totalFare = useMemo(() => {
    // Calculating total price from rooms object.
    const total = Object.entries(rooms ?? {}).map(([roomKey, room]) => {
      // Destructuring room properties.
      const { pricing } = room;

      return (pricing?.guests ?? []).map((guest) => Number(guest.total_fare));
    });

    // Returning total sum.
    return total.flat(2).reduce((acc, current) => acc + current, 0);
  }, [rooms]);

  const handleShowCancellationPolicy = () =>
    dispatch(
      showModal({
        type: MODAL.MODAL_TYPES.CANCELLATION_POLICY,
      }),
    );

  // Rendering the component structure.
  return (
    <div className={styles.container}>
      <Collapsible
        expanded={detailsExpanded}
        onExpand={setDetailsExpanded} // Handler to toggle expanded state.
        renderHeader={() => (
          <p className={styles.header}>{t("booking details")}</p> // Translated header.
        )}>
        <div className={styles.body}>
          {/* Displaying cruise name */}
          <p className={styles.name}>{cruise?.cruise.name}</p>

          {/* Embark and Debark dates */}
          <table>
            <tbody>
              {/* Row for embark date */}
              <tr>
                <td>
                  <p className={styles.tableHeading}>{t("embarks")}</p>
                </td>

                <td>
                  <p className={styles.tableValue}>
                    {dayjs(cruise?.embark ?? "").format(
                      date_format || "MM/DD/YYYY", // Formatting embark date.
                    )}
                  </p>
                </td>
              </tr>

              {/* Row for debark date, calculated based on embark date and nights */}
              <tr>
                <td>
                  <p className={styles.tableHeading}>{t("debarks")}</p>
                </td>

                <td>
                  <p className={styles.tableValue}>
                    {dayjs(cruise?.embark)
                      .add(cruise?.cruise.nights ?? 1, "days")
                      .format(date_format || "MM/DD/YYYY")}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>

          <hr className={styles.line} />

          {/* Mapping through rooms to display each room's details */}
          {Object.entries(rooms ?? {}).map(([roomKey, room]) => {
            // Destructuring room properties.
            const { grade, guestsNumber, pricing } = room;

            return (
              <div key={nanoid()} className={styles.room}>
                {/* Displaying room number and type */}
                <p
                  className={
                    styles.roomNumber
                  }>{`${t("stateroom")} ${roomKey}`}</p>

                <p className={styles.roomName}>{grade?.name ?? ""}</p>

                {/* Guest details table */}
                <table>
                  <tbody>
                    {/* Row for guests number */}
                    <tr>
                      <td>
                        <p className={styles.tableHeading}>{t("guests")}</p>
                      </td>

                      <td>
                        <p className={styles.tableValue}>
                          {`${guestsNumber} ${t("adult(s)")}`}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <hr className={styles.line} />

                {/* Mapping through each guest in a room to display their fares */}
                <div className={styles.guests}>
                  {(pricing?.guests ?? []).map((guest) => {
                    const {
                      guest: guestNumber,
                      total_price,
                      gft,
                      total_fare,
                    } = guest;

                    return (
                      <div key={guestNumber} className={styles.guest}>
                        {/* Table for individual guest fare details */}
                        <table>
                          <tbody>
                            {/* Row for fare */}
                            <tr>
                              <td>
                                <p
                                  className={classNames(
                                    styles.tableHeading,
                                    styles.guestHeading,
                                  )}>
                                  {t("fare")}
                                </p>
                              </td>

                              <td>
                                <p
                                  className={classNames(
                                    styles.tableValue,
                                    styles.guestValue,
                                  )}>
                                  {currencyToFormat(
                                    total_fare ?? 0,
                                    rooms?.[1]?.pricing?.payment_schedule?.[0]
                                      ?.currency ?? "USD",
                                  )}
                                </p>
                              </td>
                            </tr>

                            {/* Conditionally displaying row for tax if gft is not 0 */}
                            {!!+gft && (
                              <tr>
                                <td>
                                  <p
                                    className={classNames(
                                      styles.tableHeading,
                                      styles.guestHeading,
                                    )}>
                                    {t("tax")}
                                  </p>
                                </td>

                                <td>
                                  <p
                                    className={classNames(
                                      styles.tableValue,
                                      styles.guestValue,
                                    )}>
                                    {currencyToFormat(
                                      gft,
                                      rooms?.[1]?.pricing?.payment_schedule?.[0]
                                        ?.currency ?? "USD",
                                    )}
                                  </p>
                                </td>
                              </tr>
                            )}

                            {/* Row for guest subtotal */}
                            <tr>
                              <td>
                                <p
                                  className={
                                    styles.tableHeading
                                  }>{`${t("guest")} ${guestNumber} ${t("subtotal")}`}</p>
                              </td>

                              <td>
                                <p className={styles.tableValue}>
                                  {currencyToFormat(
                                    total_price,
                                    rooms?.[1]?.pricing?.payment_schedule?.[0]
                                      ?.currency ?? "USD",
                                  )}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <hr className={styles.line} />
                      </div>
                    );
                  })}
                </div>

                {/* Table for room total fare */}
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p
                          className={
                            styles.tableHeading
                          }>{`${t("stateroom")} ${roomKey} ${t("fare")}:`}</p>
                      </td>

                      <td>
                        <p className={styles.tableValue}>
                          {currencyToFormat(
                            room.pricing?.total_price ?? "0",
                            rooms?.[1]?.pricing?.payment_schedule?.[0]
                              ?.currency ?? "USD",
                          )}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <hr className={styles.line} />
              </div>
            );
          })}

          {/* Table for displaying total fare */}
          <table>
            <tbody>
              <tr>
                <td>
                  <p
                    className={classNames(
                      styles.tableHeading,
                      styles.totalPriceHeading,
                    )}>
                    {t("total fare:")}
                  </p>
                </td>

                <td>
                  <p
                    className={classNames(
                      styles.tableValue,
                      styles.totalPriceValue,
                    )}>
                    {currencyToFormat(
                      totalFare,
                      rooms?.[1]?.pricing?.payment_schedule?.[0]?.currency ??
                        "USD",
                    )}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>

          <hr className={styles.line} />

          {/* Payment schedule section */}
          <div>
            <p className={styles.roomNumber}>{t("payment schedule")}</p>

            <p
              className={classNames(
                styles.tableValue,
                styles.scheduleTableValue,
              )}>
              {t("deposit & payment option")}
            </p>
          </div>

          {/* Mapping through payment_schedule to display payment dates and amounts */}
          <table>
            <tbody>
              {Object.entries(payment_schedule).map(
                ([scheduleKey, scheduleItem]) => {
                  const { date, amount } = scheduleItem;
                  const isToday = dayjs(date).isSame(dayjs(), "day");

                  const title = isToday
                    ? t("today")
                    : dayjs(date).format(date_format || "MM/DD/YYYY");

                  return (
                    <tr key={scheduleKey}>
                      <td>
                        <p className={styles.tableHeading}>{title}</p>
                      </td>
                      <td>
                        <p className={styles.tableValue}>
                          {currencyToFormat(
                            amount,
                            rooms?.[1]?.pricing?.payment_schedule?.[0]
                              ?.currency ?? "USD",
                          )}
                        </p>
                      </td>
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>

          <hr className={styles.line} />

          {/* Payment schedule section */}
          {show_cancellation_policy && cancellation_policy.length !== 0 && (
            <div>
              <p className={styles.roomNumber}>{t("cancellation policy")}</p>

              <p
                onClick={handleShowCancellationPolicy}
                className={classNames(
                  styles.tableValue,
                  styles.cancellationPolicyValue,
                )}>
                {t("click to see cancellation policy")}
              </p>
            </div>
          )}
        </div>
      </Collapsible>

      <Collapsible
        expanded={agentExpanded}
        onExpand={setAgentExpanded} // Handler to toggle expanded state.
        renderHeader={() => (
          <p className={styles.header}>{t("agent")}</p> // Translated header.
        )}>
        <div className={styles.body}>
          {/* Embark and Debark dates */}
          <table>
            <tbody>
              {/* Row for embark date */}
              <tr>
                <td>
                  <p className={styles.tableHeading}>{t("commission")}:</p>
                </td>

                <td>
                  <p className={styles.tableValue}>{commission_level}%</p>
                </td>
              </tr>

              {/* Row for agent commission percent and amount */}
              <tr>
                <td>
                  <p className={styles.tableHeading}>{t("value")}:</p>
                </td>

                <td>
                  <p className={styles.tableValue}>
                    {currencyToFormat(
                      Number(totalFare) * (Number(commission_level) / 100),
                      rooms?.[1]?.pricing?.payment_schedule?.[0]?.currency ??
                        "USD",
                    )}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Collapsible>
    </div>
  );
}

// Exporting the component for use elsewhere.
export default BookingDetails;
