import {
  HashRouter as Router, // Importing HashRouter from react-router-dom and aliasing it as Router
  Navigate, // Importing Navigate from react-router-dom for navigation
  Route, // Importing Route from react-router-dom for defining routes
  Routes, // Importing Routes from react-router-dom for grouping routes
} from "react-router-dom"; // Importing necessary components from react-router-dom library

import useCurrentRoutes from "./hooks/useCurrentRoutes"; // Importing custom hook 'useCurrentRoutes' from './hooks/useCurrentRoutes'
import { ROOT } from "./utils/constants/routes"; // Importing ROOT constant from './utils/constants/routes'

import ToastContainer from "./components/containers/ToastContainer"; // Importing ToastContainer component
import ModalContainer from "./components/containers/ModalContainer"; // Importing ModalContainer component
import RootGuard from "./components/guards/RootGuard"; // Importing RootGuard component
import RootLayout from "./components/layouts/Root"; // Importing RootLayout component

function Navigation() {
  // Defining a functional component Navigation
  const { routes } = useCurrentRoutes(); // Destructuring routes object from the result of invoking useCurrentRoutes custom hook

  return (
    <>
      {/* React fragment shorthand */}
      <RootGuard>
        {/* Rendering RootGuard component */}
        <Router>
          {/* Wrapping the application with HashRouter for routing */}
          <ModalContainer>
            {/* Rendering ModalContainer component */}
            <Routes>
              {/* Defining routes using Routes component */}
              {Object.entries(routes).map(([routeKey, route]) => {
                // Mapping over routes object
                const { element: Element, layout } = route; // Destructuring element and layout from route object

                return (
                  <Route // Rendering Route component for each route
                    key={routeKey} // Assigning unique key to each Route component
                    path={routeKey} // Setting path for Route component
                    element={
                      // Defining element to render for this Route
                      <RootLayout type={layout}>
                        {/* Rendering RootLayout component with specified layout type */}
                        <Element />
                        {/* Rendering component associated with the route */}
                      </RootLayout>
                    }
                  />
                );
              })}

              <Route path="*" element={<Navigate to={ROOT} />} />
              {/* Rendering a wildcard Route to redirect to ROOT if no other route matches */}
            </Routes>
          </ModalContainer>
        </Router>
      </RootGuard>
      <ToastContainer /> {/* Rendering ToastContainer component */}
    </>
  );
}

export default Navigation; // Exporting Navigation component as default
