// React Router DOM for navigation
import { NavLink } from "react-router-dom";
// Classnames utility for conditional classes
import classNames from "classnames";

// Component specific styles
import styles from "./index.module.scss";

// Interface for props
interface ICustomNavLinkProps {
  label: string;
  to: string;
  className?: string;
}

// CustomNavLink component for navigation links
function CustomNavLink({ label, to, className }: ICustomNavLinkProps) {
  // Return a NavLink with conditional styling based on active state
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(styles.link, className, {
          [styles.link_active]: isActive, // Apply active class if the link is active
        })
      }>
      {label}
    </NavLink>
  );
}

// Default props
CustomNavLink.defaultProps = {
  className: "", // Default className is an empty string
};

// Export the component
export default CustomNavLink;
