// Importing CSS module styles specific to the AuthLayout component.
import styles from "./index.module.scss";

// Defining the AuthLayout functional component. It's designed to wrap and style content related to authentication flows.
// The component accepts children, allowing it to be flexible and reusable for various auth-related UIs.
function AuthLayout({ children }: React.PropsWithChildren) {
  // The component returns a main HTML element to semantically represent the primary content of the document.
  // It uses styles from the imported CSS module to ensure consistent styling.
  return (
    <main className={styles.container}>
      {/* A section element is used here to group the authentication-related content.
      It's given a class for styling and wraps the children elements passed to the AuthLayout.
      This setup allows for the insertion of any form or informational component needed for authentication tasks. */}
      <section className={styles.content}>{children}</section>
    </main>
  );
}

// Exporting the AuthLayout component to make it available for use in other parts of the application.
// This modular approach helps maintain clean and organized code, especially for larger applications with multiple auth-related pages.
export default AuthLayout;
