// Importing the RadioButton component
import RadioButton from "../RadioButton";

// Importing component-specific styles
import styles from "./index.module.scss";

// Interface for the RadioGroup component props
interface IRadioGroupProps {
  value: string; // Currently selected value
  valueKey: string; // A key to identify the radio group value
  options: Record<string | number, { value: string; label: string }>; // Options for the radio buttons
  onChange: ({ value, valueKey }: { value: string; valueKey: string }) => void; // Callback function for when the selection changes
}

// The RadioGroup component
function RadioGroup({ value, valueKey, options, onChange }: IRadioGroupProps) {
  // Function to handle changes in radio button selection
  const handleChange = (newValue: string) => () => {
    onChange({ value: newValue, valueKey }); // Calling the onChange callback with the new value and valueKey
  };

  // Returning the JSX for the radio group
  return (
    <div className={styles.container}>
      {/* Container for the radio buttons */}
      {Object.entries(options).map(([optionKey, option]) => {
        // Mapping through each option
        return (
          <RadioButton // Rendering a RadioButton for each option
            key={optionKey} // Using the optionKey as a key for the list
            label={option.label} // Passing the label to the RadioButton
            isChecked={value === option.value} // Determining if the current option is selected
            valueKey={valueKey} // Passing the valueKey to the RadioButton
            onChange={handleChange(option.value)} // Handling changes in selection
          />
        );
      })}
    </div>
  );
}

// Exporting the RadioGroup component
export default RadioGroup;
