// React hooks and utilities for routing
import React, { useCallback, useEffect, useState } from "react";
import {
  matchPath,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";

// Custom hook to get the current routes
import useCurrentRoutes from "../../../hooks/useCurrentRoutes";
// Constants for route parameters
import { RouteParams, routeParams } from "../../../utils/constants/routes";
// Hook to access the Redux store state
import { useTypedSelector } from "../../../store/store";

// Custom link component
import CustomLink from "../CustomLink";

// Component specific styles
import styles from "./index.module.scss";

// Interface for crumbs
interface CrumbInterface {
  crumbsList?: Array<[string, string, RouteParams?] | string>;
}

// Function to get the search parameters for a breadcrumb
const getCrumbSearchParams = (
  crumbParams: string[],
  searchParams: URLSearchParams,
) => {
  let output = "";

  searchParams.forEach((value, key) => {
    const firstChar = output.length === 0 ? "?" : "&";
    const shouldParamBeUsed = crumbParams.some((crumb) =>
      key.startsWith(crumb.replace("*", "")),
    );

    if (shouldParamBeUsed) {
      output += `${firstChar}${key}=${value}`;
    }
  });

  return output;
};

// Custom breadcrumbs component
function CustomBreadcrumbs({ crumbsList = [] }: CrumbInterface) {
  const [searchParams] = useSearchParams();
  const params = useParams();
  const { pathname } = useLocation();
  const { routes } = useCurrentRoutes();

  const { breadcrumbs_text } = useTypedSelector((state) => state.environment);

  const [crumbs, setCrumbs] = useState<Record<string, { title: string }>>({});

  // Get the routes entries and find the current route index
  const routesEntries = Object.entries(routes).filter(
    ([_, route]) => !!route.title,
  );

  const currentRouteIndex = routesEntries.findIndex(([path]) =>
    matchPath(path, pathname),
  );

  // Initialize the breadcrumbs
  const initCrumbs = useCallback(() => {
    const slicedRouteEntries = routesEntries.slice(0, currentRouteIndex + 1);
    const newCrumbs: Record<string, { title: string }> = {};

    slicedRouteEntries.forEach(([routeKey, route]) => {
      const routeParam = routeKey.split(":")[1]?.split("/")[0];
      const crumbPathname = routeKey.replace(
        `:${routeParam}`,
        params[routeParam] ?? "",
      );

      const crumbSearchParams = getCrumbSearchParams(
        route.crumbParams ?? [],
        searchParams,
      );

      const pathnameWithSearch = `${crumbPathname}${crumbSearchParams}`;

      newCrumbs[pathnameWithSearch] = { title: route.title ?? "" };
    });

    setCrumbs(newCrumbs);
  }, [pathname]);

  useEffect(initCrumbs, [pathname]);

  // Render the breadcrumbs
  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {crumbsList.length === 0
          ? Object.entries(crumbs)
              .slice(0, currentRouteIndex + 1)
              .map(([crumbKey, crumb], index) => {
                const isLast =
                  index ===
                  Object.entries(crumbs).slice(0, currentRouteIndex + 1)
                    .length -
                    1;

                return (
                  <React.Fragment key={crumbKey}>
                    {index !== 0 && (
                      <p style={{ margin: "0 5px", display: "inline" }}>/</p>
                    )}
                    <li key={crumbKey}>
                      <CustomLink
                        to={crumbKey}
                        isPrimary={isLast}
                        isDisabled={isLast}>
                        {crumb.title}
                      </CustomLink>
                    </li>
                  </React.Fragment>
                );
              })
          : crumbsList.map((crumb, index) => {
              const isLast = index === crumbsList.length - 1;
              let crumbKey, title, key;

              if (typeof crumb === "string") {
                crumbKey = title = crumb;
              } else {
                [crumbKey, title, key] = crumb;
              }

              if (key) {
                crumbKey = routeParams(crumbKey, key);
              }

              return (
                <React.Fragment key={crumbKey}>
                  {index !== 0 && (
                    <p style={{ margin: "0 5px", display: "inline" }}>/</p>
                  )}
                  <li key={crumbKey}>
                    <CustomLink
                      to={crumbKey}
                      isPrimary={isLast}
                      isDisabled={isLast}>
                      {title}
                    </CustomLink>
                  </li>
                </React.Fragment>
              );
            })}
      </ul>

      {breadcrumbs_text && <p className={styles.text}>{breadcrumbs_text}</p>}
    </div>
  );
}

// Wrapping the component with React.memo for performance optimization
export default React.memo(CustomBreadcrumbs);
