import { useEffect, useMemo } from "react"; // Importing necessary hooks from React
import classNames from "classnames"; // Utility for conditionally joining classNames.
import { useTranslation } from "react-i18next"; // Importing the useTranslation hook for language translation

import { useTypedSelector } from "../../../../../store/store"; // Importing the useTypedSelector hook from the Redux store
import { Room } from "../../../../../store/slices/roomsSlice"; // Import Room type
import { Grade, Prices } from "../../../../../store/slices/searchSlice"; // Import Grade and Prices types
import { currencyToFormat } from "../../../../../utils/helpers/currency"; // Importing utility function for formatting currency.
import { useLazyGetPricingQuery } from "../../../../../store/services/CabinSelectService";

import SvgIcon from "../../../../shared/SvgIcon"; // Reusable component for SVG icons.

import styles from "./index.module.scss"; // Styles specific to ModalContainer component.

// Define props interface
interface IStateroomsListProps {
  room?: Room; // Optional prop for the room object
  handleChoseStateroom: (stateroom: Room) => void; // Function prop for handling stateroom selection
  handleShowImagesModal: (images: string[]) => void; // Function prop for showing images modal
  handleShowStateroomsModal: (grade: Grade) => void; // Function prop for showing staterooms modal
}

// Calculates the prices for different occupancy types based on the given prices object.
const getPrices = (prices?: Prices) => {
  const defaultSingle = +(prices?.single ?? 0); // Convert 'single' price to number, default to 0 if undefined
  const defaultDouble = +(prices?.standard ?? 0); // Convert 'standard' price to number, default to 0 if undefined
  const defaultTriple = +(prices?.third ?? prices?.extra ?? 0); // Convert 'third' or 'extra' price to number, default to 0 if undefined
  const defaultQuadruple = +(prices?.fourth ?? prices?.extra ?? 0); // Convert 'fourth' or 'extra' price to number, default to 0 if undefined

  const single = defaultSingle > 0 ? Math.floor(+defaultSingle) : 0; // Calculate single occupancy price
  const double = defaultDouble > 0 ? Math.floor(+defaultDouble) : 0; // Calculate double occupancy price

  // Calculate triple occupancy price
  const triple =
    defaultTriple > 0
      ? Math.floor((+defaultDouble * 2 + +defaultTriple) / 3)
      : 0;

  // Calculate quadruple occupancy price
  const quadruple =
    defaultQuadruple > 0
      ? Math.floor(
          (+defaultDouble * 2 + +defaultTriple + +defaultQuadruple) / 4,
        )
      : 0;

  return { single, double, triple, quadruple };
};

/**
 * StateroomsList component displays a list of stateroom options with pricing and allows the user to select one.
 *
 * @param {Room} room - The room object containing information about the stateroom.
 * @param {Function} handleChoseStateroom - A function to handle the selection of a stateroom.
 * @param {Function} handleShowImagesModal - A function to handle displaying images of the stateroom.
 * @param {Function} handleShowStateroomsModal - A function to handle displaying more details about the stateroom.
 * @returns {JSX.Element} StateroomsList component JSX elements.
 */

function StateroomsList({
  room,
  handleChoseStateroom,
  handleShowImagesModal,
  handleShowStateroomsModal,
}: IStateroomsListProps) {
  const { t } = useTranslation(); // Initializing the useTranslation hook for accessing i18n translation functionalities

  const { cruise } = useTypedSelector((state) => state.search); // Accessing cruise data from Redux store
  const { subtract_gft, show_pricing_breakdown } = useTypedSelector(
    (state) => state.environment,
  ); // Accessing environment settings from Redux store

  const [getPricing] = useLazyGetPricingQuery(); // Lazy initialization hook for getting pricing.

  // useMemo hook to memoize the market data based on room's market type and cruise markets
  const market = useMemo(() => {
    if (room?.marketType && cruise?.markets) {
      return cruise?.markets[
        room?.marketType as "suite" | "inside" | "balcony" | "outside"
      ];
    }
  }, [room?.marketType]);

  // useMemo hook to memoize the grades by market type based on room's fare prices
  const gradesByMarketType: Grade[] | undefined = useMemo(() => {
    const farePricesCodes = room?.fare?.prices.map((price) => price.grade); // Extracts the grade codes from the room's fare prices.

    // Filters ship grades based on the room's market type and fare prices codes.
    const grades = cruise?.ship.grades.filter(
      (grade) =>
        grade.meta_category === room?.marketType &&
        farePricesCodes?.includes(grade.code),
    );

    // Maps grades to include price information from the room's fare.
    const gradesWithPrice = grades?.map((grade) => {
      const clonedGrade = structuredClone(grade); // Clones the grade object.

      // Finds the fare grade corresponding to the current grade.
      const fareGrade = room?.fare?.prices.find(
        (el) => el.grade === grade.code,
      );

      // Calculates prices for different occupancy levels.
      const { single, double, triple, quadruple } = getPrices(fareGrade);

      // Assigns calculated prices to the cloned grade object.
      clonedGrade.single = single;
      clonedGrade.double = double;
      clonedGrade.triple = triple;
      clonedGrade.quadruple = quadruple;

      return clonedGrade;
    });

    // Sorts grades by size.
    if (gradesWithPrice) {
      const sortPrice = gradesWithPrice.sort(function (a, b) {
        const priceA = a.double === 0 ? a.single : a.double;
        const priceB = b.double === 0 ? b.single : b.double;

        return Number(priceA) - Number(priceB);
      });

      return sortPrice;
    } else {
      return gradesWithPrice;
    }
  }, [room]);

  // Function to handle image click event and show images modal
  const handleImageClick = (images: string[]) => () => {
    handleShowImagesModal(images);
  };

  // Function to handle stateroom details click event and show staterooms modal
  const handleStateroomsDetailsClick = (grade: Grade) => () => {
    handleShowStateroomsModal(grade);
  };

  // Function to handle updating chosen grades
  const handleUpdateChosenGrades =
    (grade: Grade, guestsNumber: number) => () => {
      const updatedStateroom = structuredClone({
        ...room,
        grade,
        guestsNumber,
      });

      setTimeout(() => {
        // If need to show pricing breakdown try to find element with id continue-2 and scroll to this element else same with element continue
        if (show_pricing_breakdown) {
          const element = document.getElementById("continue-2");

          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        } else {
          const element = document.getElementById("continue");

          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "end" });
          }
        }
      }, 1000);

      // Call handleChoseStateroom function
      handleChoseStateroom(updatedStateroom);
    };

  const requestPrice = async () => {
    const clonedRoom: Room = structuredClone(room ?? {});
    const guestsDobQs: string[] = [];

    // Constructing guest date of birth queries.
    for (let i = 1; i <= (clonedRoom?.guestsNumber ?? 0); i++) {
      guestsDobQs.push(`guest_dob_${i}=1979-04-22`);
    }

    // Payload for pricing query.
    const payload = {
      sailing_code: cruise?.code ?? "",
      source: cruise?.source ?? "",
      rate_code: clonedRoom?.fare?.rate_code ?? "",
      grade_code: clonedRoom?.grade?.code ?? "",
      cabin_number: "GTY",
      guest_count: `${clonedRoom?.guestsNumber ?? 1}`,
      guests_dobs: guestsDobQs.join("&"),
    };

    await getPricing(payload);
  };

  useEffect(() => {
    if (show_pricing_breakdown && room?.guestsNumber !== undefined) {
      requestPrice();
    }
  }, [room]);

  return (
    // Container for the staterooms list
    <div className={styles.container}>
      {/* Block containing staterooms list */}
      <div className={styles.block}>
        {/* Title for the pricing section */}
        <p className={styles.title} id="pricing">
          {t("pricing per person")}
        </p>
        {/* Container for displaying stateroom grades */}
        <div className={styles.grades}>
          {/* Conditional rendering based on availability of stateroom grades */}
          {gradesByMarketType?.length ? (
            // Mapping through each stateroom grade
            gradesByMarketType?.map((grade) => {
              const { single, double, triple, quadruple } = grade;

              // Object for prices based on guest numbers
              const pricesByGuestNumber: Record<number, number> = {
                1: single,
                2: double,
                3: triple,
                4: quadruple,
              };

              return (
                // Container for each stateroom grade
                <div key={grade.code} className={styles.grade}>
                  {/* Header section of stateroom grade */}
                  <div className={styles.gradeHeader}>
                    {/* Conditional rendering for stateroom images */}
                    {grade.images.length ? (
                      // Image container with click event handler
                      <div
                        className={styles.imageContainer}
                        onClick={handleImageClick(grade.images)}>
                        {/* Loupe icon for image zoom */}
                        <div className={styles.loupe}>
                          <SvgIcon type="loupe" />
                        </div>

                        {/* Image thumbnail */}
                        <img src={grade.images[0]} className={styles.image} />
                      </div>
                    ) : (
                      // Placeholder for missing image
                      <div className={styles.blankImage}>
                        <p>{t("no pictures")}</p>
                      </div>
                    )}

                    {/* Mapping through each possible number of guests */}
                    {[1, 2, 3, 4].map((guestsNumber) => {
                      // Guest title based on number of guests
                      const guestTitle =
                        guestsNumber === 1 ? t("guest") : t("guests");

                      // Price calculation based on guest number and grade data
                      const price =
                        guestsNumber > grade.maximum_occupancy ||
                        guestsNumber < grade.minimum_occupancy ||
                        pricesByGuestNumber[guestsNumber] <= 0
                          ? "N/A"
                          : currencyToFormat(
                              pricesByGuestNumber[guestsNumber] -
                                +(subtract_gft ? market?.gft ?? "0" : 0),
                              cruise?.markets.currency ?? "USD",
                            ).slice(0, -3);

                      // Boolean indicating whether the grade is disabled
                      const isDisabled = price === "N/A";

                      // Boolean indicating whether the grade is active or chosen
                      const isActive =
                        room?.grade?.code === grade.code &&
                        room?.guestsNumber === guestsNumber;

                      return (
                        <div
                          key={guestsNumber}
                          className={classNames(
                            styles.market,
                            styles.gradePrice,
                            {
                              [styles.market_active]: isActive, // Apply active style if isActive is true
                              [styles.market_disabled]: isDisabled, // Apply disabled style if isDisabled is true
                            },
                          )}
                          {...(!isDisabled
                            ? {
                                onClick: handleUpdateChosenGrades(
                                  grade,
                                  guestsNumber,
                                ), // Assign onClick event if not disabled
                              }
                            : {})}>
                          {/* Display guest number and title */}
                          <p
                            className={
                              styles.marketName
                            }>{`${guestsNumber} ${guestTitle}`}</p>

                          <p className={styles.price}>{`${price ?? 0}${
                            isDisabled ? "" : "pp"
                          }`}</p>
                        </div>
                      );
                    })}
                  </div>

                  {/* Body section of stateroom grade */}
                  <div className={styles.gradeBody}>
                    {/* Grade name */}
                    <p className={styles.gradeName}>
                      {grade.name}
                      {` (${grade.code})`}
                    </p>
                    {/* Grade size */}
                    <p className={styles.gradeSize}>{grade.size}</p>

                    {/* Grade description */}
                    <p className={styles.gradeDescription}>
                      {grade.descriptions?.[0].description ?? ""}
                    </p>
                  </div>

                  {/* Footer section of stateroom grade */}
                  <div className={styles.gradeFooter}>
                    {/* Link to find out more about the staterooms */}
                    <p onClick={handleStateroomsDetailsClick(grade)}>
                      {t("find out more")}
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            // Message displayed when there is no data available
            <p>{t("no data to display")}</p>
          )}
        </div>
      </div>
    </div>
  );
}

// Exporting the StateroomsList component
export default StateroomsList;
