/**
 * Provides functionality related to localization using i18next.
 */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import enTranslations from "./locales/en/translation.json";
import esTranslations from "./locales/es/translation.json";

/**
 * Supported languages.
 */
const LANGUAGES = {
  EN: "en",
  ES: "es",
};

/**
 * Language detection configuration.
 */
const detection = {
  order: ["querystring", "navigator"],
  lookupQuerystring: "lng",
};

/**
 * Detects the language based on local storage or defaults to English.
 * @returns {string} The detected language.
 */
export const detectLanguage = () => {
  const localLanguage = localStorage.getItem("APP_LANG") ?? LANGUAGES.EN;

  return localLanguage;
};

/**
 * Resources containing translations for each language.
 */
const resources = {
  [LANGUAGES.EN]: {
    translation: enTranslations,
  },
  [LANGUAGES.ES]: {
    translation: esTranslations,
  },
};

/**
 * Language detector configuration.
 */
const languageDetector = {
  type: "languageDetector",
  detect: () => {
    const lng = detectLanguage();

    return lng;
  },
  init: () => {},
  cacheUserLanguage: () => {},
};

/**
 * Initializes i18next with the configured settings.
 */
i18n
  .use(initReactI18next)
  .use(languageDetector)
  .init({
    detection,
    resources,
    fallbackLng: LANGUAGES.EN,
    supportedLngs: Object.values(LANGUAGES),
    interpolation: {
      escapeValue: false,
    },
    debug: false,
  });

export default i18n;
