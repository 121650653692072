const agentRegistrationRules = {
  sales_area: { one_of: [null] },
  name: ["required", "trim", "string"],
  firstName: ["required", "trim", "string"],
  surname: ["required", "trim", "string"],
  address: ["required", "trim", "string"],
  city: ["required", "trim", "string"],
  state: ["required", "trim", "string"],
  zip: ["required", "trim", "string"],
  country: ["required", "trim", "string"],
  telephone: ["required", "trim", "string"],
  agency: ["required", "trim", "string"],
  position: ["required", "trim", "string"],
  email: ["required", "trim", "email"],
  b2b_password: ["required", "trim", "string", { min_length: 8 }],
  confirm_b2b_password: [
    "required",
    "trim",
    "string",
    { equal_to_field: "b2b_password", min_length: 8 },
  ],
  is_web: { one_of: [true, false] },
  is_default: { one_of: [true, false] },
  skip_external: { one_of: [true, false] },
};

export default agentRegistrationRules;
