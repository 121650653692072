import agentSession from "./agentSession";
import forgottenPassword from "./forgottenPassword";
import search from "./search";
import rooms from "./rooms";
import agencyRegistration from "./agencyRegistration";
import agentRegistration from "./agentRegistration";
import guestForm from "./guestForm";

const rules = {
  agentSession,
  forgottenPassword,
  search,
  rooms,
  agencyRegistration,
  agentRegistration,
  guestForm,
};

export default rules;
