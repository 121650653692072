import { baseApi } from "./apiSingleton"; // Importing baseApi from apiSingleton file

// Interface defining the response structure for initializing API token
export interface IInitApiTokenResponse {
  token: string; // Token received from the API
}

// Interface defining the response structure for initializing agent session
export interface IInitAgentSessionResponse {
  id: string; // Agent ID
  name: string; // Agent name
  agency: string; // Agency associated with the agent
}

// Interface defining the response structure for sending reset token
export interface ISendResetTokenResponse {
  success: boolean; // Indicates whether the reset token sending was successful
}

// Interface defining the response structure for resetting password
export interface IResetPasswordResponse {
  success: boolean; // Indicates whether the password reset was successful
}

// Interface defining the response structure for initializing agent dashboard
export interface IInitAgentDashboardResponse {
  success: boolean; // Indicates whether the initialization of agent dashboard was successful
}

// Interface defining the request structure for initializing API token
export interface IInitApiTokenRequest {
  username: string; // Username for authentication
  password: string; // Password for authentication
}

// Interface defining the request structure for initializing agent session
export interface IInitAgentSessionRequest {
  email: string; // Agent's email
  password: string; // Agent's password
}

// Interface defining the request structure for sending reset token
export interface ISendResetTokenRequest {
  email: string; // Email address to send the reset token
}

// Interface defining the request structure for resetting password
export interface IResetPasswordRequest {
  password: string; // New password
  reset_token: string; // Reset token received by the user
}

// Type defining the request structure for initializing agent dashboard
export type IInitAgentDashboardRequest = string; // The request consists of a string parameter

// Creating SessionApi by injecting endpoints into baseApi
export const SessionApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // Defining endpoint for initializing API token
    initApiToken: builder.query<IInitApiTokenResponse, IInitApiTokenRequest>({
      query: (body) => ({
        url: "/api/token/", // API endpoint for initializing API token
        method: "POST", // HTTP method
        body, // Request body
      }),
    }),

    // Defining endpoint for initializing agent session
    initAgentSession: builder.query<
      IInitAgentSessionResponse,
      IInitAgentSessionRequest
    >({
      query: (body) => ({
        url: "/ops/agent/b2b/", // API endpoint for initializing agent session
        method: "POST", // HTTP method
        body, // Request body
      }),
    }),

    // Defining endpoint for sending reset token
    sendResetToken: builder.query<
      ISendResetTokenResponse,
      ISendResetTokenRequest
    >({
      query: (body) => ({
        url: "/ops/agent/forgotten/", // API endpoint for sending reset token
        method: "POST", // HTTP method
        body, // Request body
      }),
    }),

    // Defining endpoint for resetting password
    resetPassword: builder.query<IResetPasswordResponse, IResetPasswordRequest>(
      {
        query: (body) => ({
          url: "/ops/agent/reset_password/", // API endpoint for resetting password
          method: "POST", // HTTP method
          body, // Request body
        }),
      },
    ),

    // Defining endpoint for initializing agent dashboard
    initAgentDashboard: builder.query<null, null>({
      query: () => ({
        url: "/dashboard/dashboard/", // API endpoint for initializing agent dashboard
        method: "GET", // HTTP method
      }),
    }),
  }),
});

// Destructuring useful hooks from SessionApi
export const {
  useLazyInitAgentSessionQuery,
  useLazySendResetTokenQuery,
  useLazyResetPasswordQuery,
  useLazyInitAgentDashboardQuery,
} = SessionApi; // Exporting hooks for initiating agent session, sending reset token, resetting password, and initiating agent dashboard
