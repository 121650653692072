// Disabling rule for preferring nullish coalescing operator
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
// Importing utility for dynamically combining class names
import classNames from "classnames";

// Importing components for loading indicator and SVG icons
import LoadingIndicator from "../LoadingIndicator";
import SvgIcon from "../SvgIcon";

// Importing styles specific to the Button component
import styles from "./index.module.scss";

// Defining the props interface for the Button component
interface IButtonProps {
  id?: string;
  label: string;
  type?: "button" | "submit" | "reset";
  icon?: string;
  variant?: "primary" | "secondary" | "dark" | "link";
  onClick?: () => void | Promise<void>;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
}

// Function component for Button, utilizing the props interface
function Button({
  id,
  label,
  type,
  icon,
  variant,
  onClick,
  loading,
  disabled,
  className,
}: IButtonProps) {
  return (
    // Render a button element with dynamic classes and properties
    <button
      id={id} // Button ID
      className={classNames(
        styles.button, // Base button style
        styles[`button_${variant ?? "primary"}`], // Variant style
        className, // Custom class names
        { [styles.button_withIcon]: icon }, // Conditional class for buttons with icons
      )}
      type={type} // Button type (button, submit, reset)
      disabled={loading || disabled} // Disable button when loading or explicitly disabled
      onClick={onClick}>
      {loading ? <LoadingIndicator width={30} height={30} /> : label}

      {icon && <SvgIcon type={icon} />}
    </button>
  );
}

// Default props for the Button component
Button.defaultProps = {
  type: "button",
  icon: "",
  variant: "primary",
  onClick: () => {},
  loading: false,
  disabled: false,
  className: "",
};

// Export the Button component for use in other parts of the application
export default Button;
